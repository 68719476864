// src/components/Profile.tsx
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Menu, Transition } from '@headlessui/react';
import { t } from 'i18next';
import { Fragment } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { RiLogoutBoxLine } from 'react-icons/ri';

import { loginAsync, logoutAsync } from '../../services/auth';

const Profile = () => {
    const { instance, inProgress } = useMsal();
    const activeAccount = instance?.getActiveAccount();
    if (
        inProgress !== InteractionStatus.HandleRedirect &&
        inProgress !== InteractionStatus.Logout &&
        inProgress !== InteractionStatus.Login
    ) {
        if (activeAccount) {
            return (
                <>
                    <Menu
                        as='div'
                        className='relative ml-3 bg-primary text-primary-content'
                    >
                        <div>
                            <Menu.Button className='relative flex rounded-md p-2 text-sm '>
                                <p className='text-sm '>
                                    {activeAccount?.name}
                                </p>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'
                        >
                            <Menu.Items className='flex flex-col absolute right-0 z-10 py-1 mt-2  origin-top-right rounded-md  shadow-lg ring-1 ring-opacity-5 focus:outline-none bg-base-100 text-base-content'>
                                <Menu.Item disabled>
                                    {() => (
                                        <p className='block px-4 py-2 text-sm border-b'>
                                            {activeAccount?.username}
                                        </p>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={`flex items-center px-4 py-2 rounded-md text-sm ${
                                                active
                                                    ? 'bg-base-300 hover:bg-base-300/50'
                                                    : ''
                                            } `}
                                            onClick={async () => {
                                                logoutAsync();
                                            }}
                                        >
                                            <RiLogoutBoxLine className='inline-block w-4 h-4 mr-2' />
                                            <p className='px-1'>
                                                {t('navbar.signOut')}
                                            </p>
                                        </button>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </>
            );
        } else {
            return (
                <>
                    <button
                        className='px-3 py-2 rounded-md text-sm font-medium'
                        onClick={async () => {
                            await loginAsync();
                        }}
                    >
                        {t('navbar.signIn')}
                    </button>
                </>
            );
        }
    }

    return (
        <div className='flex items-center justify-center h-screen'>
            <AiOutlineLoading3Quarters className='animate-spin h-6 w-6' />
        </div>
    );
};

export default Profile;
