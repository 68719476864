import React from 'react';

export const About = () => {
    return (
        <div className='flex flex-col flex-1'>
            <p className='text-2xl'>Demopro</p>
            <p>Version:{import.meta.env.VITE_APP_VERSION}</p>
            <p>
                Contacts:{' '}
                <a className='link underline' href='mailto:hanxia@outlook.com'>
                    hanxia@outlook.com
                </a>
            </p>
        </div>
    );
};

export default About;
