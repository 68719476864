//RoleState.ts
export interface PageData {
    id: string;
    input: string;
    output: string;
    params: {
        [key: string]: string | number | boolean | string[] | undefined;
    };
}

export interface PagesData {
    pages: PageData[];
    currentPageIndex: number;
}

export interface RoleState {
    [userId: string]: {
        currentRoleId: string;
        data: {
            [roleId: string]: PagesData;
        };
    };
}

export const initialRoleState: RoleState = {};
