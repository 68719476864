import { Menu, Transition } from '@headlessui/react';
import { t } from 'i18next';
import React, { Fragment } from 'react';
import type { DragSourceMonitor } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';
import type { IconType } from 'react-icons';
import { FiMoreHorizontal } from 'react-icons/fi'; // 引入更多操作的图标

export interface MenuItem {
    id: 'Remove' | 'Edit';
    name: string;
    icon?: IconType;
    onClick: () => void;
}

export interface Item {
    id: string;
    roleId: string;
    title: string;
    icon: string;
    onClick?: () => void;
    menuItems: MenuItem[];
    [key: string]: any;
}

export interface SidebarItemProps {
    item: Item;
    isOpen: boolean;
    isSelected: boolean;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
    item,
    isOpen,
    isSelected,
}) => {
    const handleClick = () => {
        item?.onClick?.();
    };

    return (
        <div
            className={`flex flex-row flex-none justify-between rounded-md items-center transition-colors duration-300 ease-in-out ${
                isSelected ? 'bg-base-300' : 'hover:bg-base-300/50'
            }`}
        >
            <button
                type='button'
                title={item.title}
                key={'link-' + item.title}
                className={`btn-ghost flex flex-row flex-shrink-0 flex-grow content-start justify-start items-center p-2`}
                onClick={handleClick}
            >
                <img
                    src={item.icon}
                    alt={item.title}
                    className='flex-shrink-0 flex-grow-0 w-8 h-8'
                />
                <p
                    className={`ml-2 p-2 flex flex-shrink-0 flex-grow flex-nowrap justify-start truncate max-w-[80px] sm:max-w-[144px] lg:max-w-[200px] transition-opacity duration-50  ${
                        isOpen ? 'opacity-100 delay-300' : 'opacity-0'
                    }`}
                >
                    {item.title}
                </p>
            </button>
            {item.menuItems?.length > 0 && (
                <Menu as='div' className='relative mx-2 items-center'>
                    <div>
                        <Menu.Button
                            className={`relative rounded-md transition-opacity duration-50 p-2 ${
                                isOpen ? 'opacity-100 delay-300' : 'opacity-0'
                            }`}
                        >
                            <FiMoreHorizontal />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                    >
                        <Menu.Items className='bg-base-100 text-base-content flex flex-col flex-none w-fit justify-start item-center absolute right-0 z-10 mt-2 origin-top-right rounded-md py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none'>
                            {item.menuItems.map((item) => (
                                <div
                                    className='flex flex-row px-1'
                                    key={item.id}
                                >
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`flex flex-none flex-row items-center w-full px-2 rounded-md text-sm ${
                                                    active
                                                        ? 'bg-base-300 hover:bg-base-300/50 text-base-content/40 hover:text-base-content/60'
                                                        : ''
                                                } `}
                                                onClick={async () => {
                                                    item.onClick();
                                                }}
                                            >
                                                {item.icon && (
                                                    <item.icon className='w-8 h-8 mx-2' />
                                                )}

                                                <p className='flex-none flex flex-row'>
                                                    {t(item.name)}
                                                </p>
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            ))}
                        </Menu.Items>
                    </Transition>
                </Menu>
            )}
        </div>
    );
};

interface DraggableSidebarItemProps extends SidebarItemProps {
    index: number;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
}

export const DraggableSidebarItem: React.FC<DraggableSidebarItemProps> = ({
    item,
    isOpen,
    isSelected,
    index,
    moveItem,
}) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: 'sidebarItem',
        drop(item: { type: string; index: number }) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
            console.log('call moveItem');
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'sidebarItem',
        item: () => {
            return { index };
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={`${isDragging ? 'opacity-50' : 'opacity-100'}`}
        >
            <SidebarItem isOpen={isOpen} isSelected={isSelected} item={item} />
        </div>
    );
};
