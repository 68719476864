import { useMsal } from '@azure/msal-react';
import React, { useMemo } from 'react';
// src/components/chat/ChatHistory.tsx
import { MdClose } from 'react-icons/md';

import type { IChatMessage } from '../../models/ChatMessage';
import type { IRoleBase } from '../../models/Role';
import { useDeleteMessagesByIdMutation } from '../../services/messageApi';

import ChatMessageItem from './ChatMessageItem';

const BotTypingIndicator: React.FC = () => {
    return (
        <div className='flex space-x-2 justify-center items-start m-2 px-2'>
            <span className='block bg-base-content  w-1 h-1 rounded-full animate-typing'></span>
            <span className='block bg-base-content  w-1 h-1 rounded-full animate-typing  animation-delay-400'></span>
            <span className='block bg-base-content  w-1 h-1 rounded-full animate-typing animation-delay-800'></span>
        </div>
    );
};

interface ChatHistoryProps {
    messages: IChatMessage[] | undefined;
    role: IRoleBase;
    isTyping: boolean;
}

const groupMessagesByIdPrefix = (
    messages: IChatMessage[],
): IChatMessage[][] => {
    const groups: Record<string, IChatMessage[]> = {};
    if (messages) {
        messages.forEach((message: IChatMessage) => {
            const prefix = message.id.split('-')[0];
            if (!groups[prefix]) {
                groups[prefix] = [];
            }
            groups[prefix].push(message);
        });
        // 返回分组后的数组
        return Object.values(groups);
    }
    return [];
};

export const ChatHistory: React.FC<ChatHistoryProps> = ({
    messages,
    role,
    isTyping,
}) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const userId = account?.localAccountId ?? '';
    const [deleteMessagesById] = useDeleteMessagesByIdMutation();

    const messageGroups = useMemo(() => {
        if (messages) {
            return groupMessagesByIdPrefix(messages || []);
        }
        return [];
    }, [messages]);

    if (!role || !messages) {
        return null;
    }

    const handleDeleteGroup = async (groupIndex: number) => {
        try {
            const groupToDelete = messageGroups[groupIndex];
            const messageId = groupToDelete?.[0]?.id?.split('-')[0];
            if (messageId) {
                // 调用mutation函数来清除消息
                await deleteMessagesById({
                    roleId: role.id,
                    userId,
                    messageId,
                });
            }
        } catch (error) {
            // 处理可能的错误
            console.error('Failed to clear messages:', error);
        }
    };

    return (
        <div className='flex flex-col flex-auto bg-base-200 text-base-content rounded-md'>
            {messageGroups?.map((group, groupIndex) => (
                <div
                    key={group[0].id}
                    className='relative group flex flex-col m-1 rounded-md hover:bg-base-300'
                >
                    <button
                        onClick={async () => handleDeleteGroup(groupIndex)}
                        className={`btn-ghost rounded-full absolute flex flex-row ${
                            group[0].authorRole === 'User'
                                ? 'top-0 left-0'
                                : 'top-0 right-0'
                        } m-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out`}
                    >
                        <MdClose className='w-6 h-6' />
                    </button>
                    {group?.map((message) => (
                        <ChatMessageItem
                            key={message.id}
                            message={message}
                            role={role}
                        />
                    ))}
                </div>
            ))}
            <div className='flex flex-row flex-auto justify-start'>
                {isTyping && <BotTypingIndicator />}
            </div>
        </div>
    );
};

export default ChatHistory;
