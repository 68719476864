//Selector.tsx
import { t } from 'i18next';
import React, { useState, useEffect, useCallback } from 'react';
import { MdCheck, MdSave, MdClose } from 'react-icons/md';

import type { IRole, IRoleBase } from '../../models/Role';
import {
    useDeleteRoleMutation,
    useGetRolesQuery,
} from '../../services/sessionApi';

interface DeleteProps {
    onClose: () => void;
}

export const RoleDelete: React.FC<DeleteProps> = ({ onClose }) => {
    const { data: rolesData, refetch: refetchRoles } = useGetRolesQuery();
    const [deleteRole] = useDeleteRoleMutation();
    const [allRoles, setAllRoles] = useState<IRoleBase[]>([]);
    const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);

    useEffect(() => {
        refetchRoles();
    }, [refetchRoles]);

    useEffect(() => {
        if (rolesData) {
            const convertedItems = rolesData.map((item: IRole) => {
                const converted = {
                    id: item.id,
                    title: item.title,
                    icon: item.icon,
                    readonly: item.readonly,
                    shareLevel: item.shareLevel,
                    category: item.category,
                };
                return converted;
            });
            setAllRoles(convertedItems);
        }
    }, [rolesData]);

    const toggleRoleSelection = (roleId: string) => {
        setSelectedRoleIds((prevSelectedRoleIds) => {
            return prevSelectedRoleIds.includes(roleId)
                ? prevSelectedRoleIds.filter((id) => id !== roleId)
                : [...prevSelectedRoleIds, roleId];
        });
    };

    const handleToggleSelect = (
        event:
            | React.MouseEvent<HTMLElement>
            | React.KeyboardEvent<HTMLDivElement>,
        roleId: string,
    ) => {
        if ('key' in event && event.key !== ' ') {
            return;
        }
        event.stopPropagation();
        event.preventDefault();
        toggleRoleSelection(roleId);
    };

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handlConfirm = async () => {
        try {
            selectedRoleIds.forEach(async (roleId) => {
                await deleteRole(roleId);
                const filteredRoles = allRoles.filter(
                    (role) => role.id !== roleId,
                );
                setAllRoles(filteredRoles);
            });
            setSelectedRoleIds([]);
        } catch (error) {
            // 处理更新失败的情况
        }
    };
    return (
        <>
            {
                <div className='bg-base-100 text-base-content flex flex-col flex-1 overflow-auto'>
                    <div className='flex flex-row justify-between p-2'>
                        <p className='label flex-1 text-lg p-2'>
                            {t('role.selectRolesToDelete')}
                        </p>
                        <div className='bg-base-100 text-base-content flex flex-row flex-none text-lg  p-2'>
                            <div className='flex flex-row justify-end px-2'>
                                <button
                                    onClick={handlConfirm}
                                    disabled={!(selectedRoleIds?.length > 0)}
                                    className={`btn btn-primary btn-outline mr-2  items-center justify-center px-2 text-sm ${
                                        selectedRoleIds?.length > 0
                                            ? ''
                                            : 'btn-disabled'
                                    }`}
                                    aria-label={t('global.confirm')}
                                >
                                    <MdSave className='w-6 h-6 ' />
                                    {t('global.confirm')}
                                </button>
                                <button
                                    onClick={handleClose}
                                    className='btn btn-primary btn-ghost inline-flex items-center justify-center px-2 py-0 rounded-full '
                                    aria-label={t('global.close')}
                                >
                                    <MdClose className='w-6 h-6 text-primary' />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='flex-1 flex flex-row flex-wrap gap-2 justify-start content-start m-6'>
                        {allRoles.map(
                            (role) =>
                                !role.readonly && (
                                    <div
                                        id={role.id}
                                        key={role.id}
                                        role='button'
                                        tabIndex={0}
                                        className={`flex flex-col items-center justify-end relative m-2 border rounded-md`}
                                    >
                                        <div
                                            className='bg-base-100 absolute top-0 right-0 w-6 h-6 border border-base-300 rounded-sm cursor-pointer'
                                            role='button'
                                            tabIndex={0}
                                            onClick={(event) =>
                                                handleToggleSelect(
                                                    event,
                                                    role.id,
                                                )
                                            }
                                            onKeyDown={(event) =>
                                                handleToggleSelect(
                                                    event,
                                                    role.id,
                                                )
                                            }
                                        >
                                            {selectedRoleIds.find(
                                                (id) => id === role.id,
                                            ) && (
                                                <MdCheck className='w-5 h-5 text-primary absolute top-0 right-0 ' />
                                            )}
                                        </div>
                                        <img
                                            src={role.icon}
                                            alt={role.title}
                                            className='w-20 h-24 max-w-full max-h-full object-cover'
                                        />
                                        <p className='text-xs truncate max-w-[96px]'>
                                            {role.title}
                                        </p>
                                    </div>
                                ),
                        )}
                    </div>
                </div>
            }
        </>
    );
};

export default RoleDelete;
