// src/services/signalRConnection.ts
import type { HubConnection } from '@microsoft/signalr';
import {
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
    JsonHubProtocol,
    HttpTransportType,
} from '@microsoft/signalr';

import { joinUrls } from '../utils/format';

let hubConnection: signalR.HubConnection | null = null;

const setupSignalRConnectionToChatHub = async () => {
    const signalRConnectionOptions = {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        logger: LogLevel.Information,
    };

    // Create the connection instance
    // withAutomaticReconnect will automatically try to reconnect and generate a new socket connection if needed
    hubConnection = new HubConnectionBuilder()
        .withUrl(
            joinUrls(import.meta.env.VITE_BACKEND_URI, '/messageRelayHub'),
            signalRConnectionOptions,
        )
        .withAutomaticReconnect()
        .withHubProtocol(new JsonHubProtocol())
        .configureLogging(LogLevel.Information)
        .build();

    // Note: to keep the connection open the serverTimeout should be
    // larger than the KeepAlive value that is set on the server
    // keepAliveIntervalInMilliseconds default is 15000 and we are using default
    // serverTimeoutInMilliseconds default is 30000 and we are using 120000 set below
    hubConnection.serverTimeoutInMilliseconds = 60000;

    return hubConnection;
};

const startSignalRConnection = async () => {
    try {
        if (hubConnection) {
            hubConnection.onclose((error) => {
                if (hubConnection?.state === HubConnectionState.Disconnected) {
                    const errorMessage =
                        'Connection closed due to error. Try refreshing this page to restart the connection';
                    console.log(errorMessage, error);
                    //hubConnection = null;
                }
            });

            hubConnection.onreconnecting((error) => {
                if (hubConnection?.state === HubConnectionState.Reconnecting) {
                    const errorMessage =
                        'Connection lost due to error. Reconnecting...';
                    console.log(errorMessage, error);
                }
            });

            await hubConnection.start();
        }
    } catch (err) {
        console.error('SignalR Connection Error: ', err);
    }
};

export const waitForStateChange = async (): Promise<void> => {
    console.log('Waiting for state change...');
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (
                hubConnection?.state !== HubConnectionState.Connecting &&
                hubConnection?.state !== HubConnectionState.Reconnecting &&
                hubConnection?.state !== HubConnectionState.Disconnecting
            ) {
                clearInterval(interval);
                resolve();
            }
        }, 1500); // Check every 1.5 seconds
    });
};

//This function will return the singleton instance of the SignalR connection
export const getOrCreateHubConnection = async (
    retries: number = 5,
): Promise<HubConnection | null> => {
    switch (hubConnection?.state) {
        case HubConnectionState.Connected:
            console.log('Get HubConnection');
            return hubConnection;
        case HubConnectionState.Connecting:
        case HubConnectionState.Reconnecting:
        case HubConnectionState.Disconnecting:
            await waitForStateChange();
            if (retries > 0) {
                return getOrCreateHubConnection(retries - 1);
            }
            return null;
        case HubConnectionState.Disconnected:
        default:
            console.log('Create HubConnection');
            setupSignalRConnectionToChatHub();
            await startSignalRConnection();
            //await waitForStateChange();

            return hubConnection;
    }
};
