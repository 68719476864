// src/components/ContrentTransform.tsx

import { useMsal } from '@azure/msal-react';
import * as pdfjs from 'pdfjs-dist';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Markdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { v1 as uuidv1 } from 'uuid';

import { useAnswer } from '../hooks/useAnswer';
import type { IInput } from '../models/ChatInput';
import {
    addPage,
    updateCurrentPage,
    setCurrentPageIndex,
    updatePageInput,
    updatePageOutput,
    deletePage,
    updatePageKeywords,
} from '../redux/features/role/roleReducer';
import type { PageData } from '../redux/features/role/RoleState';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useClearMessagesMutation } from '../services/messageApi';
import { generateJsonInput } from '../utils/format';

import { CodeBlock } from './chat/CodeBlock';
import Pagination from './Pagination';

interface ContentGenerationProps {
    roleId: string;
    features?: {
        showKeywords?: boolean;
        showLanguage?: boolean;
        showSize?: boolean;
        enableContent?: boolean;
        enableOutline?: boolean;
    };
}

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

const ContentGeneration: React.FC<ContentGenerationProps> = ({
    roleId,
    features,
}) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const userId = account?.localAccountId ?? '';
    const dispatch = useAppDispatch();

    const pages = useAppSelector(
        (state) => state.role?.[userId]?.data?.[roleId]?.pages ?? [],
    );
    const currentPageIndex = useAppSelector(
        (state) => state.role?.[userId]?.data?.[roleId]?.currentPageIndex ?? 0,
    );
    const { t } = useTranslation();

    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

    const [clearMessages] = useClearMessagesMutation();
    // 使用当前页面的messageId初始化useAnswer钩子
    const { messages, sendMessage } = useAnswer(
        roleId,
        pages?.[currentPageIndex]?.id ?? '',
    );

    React.useEffect(() => {
        if (!pages || pages.length === 0) {
            createNewPage();
        }
    }, [roleId, pages.length]);

    React.useEffect(() => {
        if (messages && messages.length > 0) {
            {
                const currentBotMessage = messages.find(
                    (msg) => msg.id === pages[currentPageIndex]?.id + '-bot',
                );
                if (currentBotMessage) {
                    dispatch(
                        updatePageOutput({
                            userId,
                            roleId,
                            pageIndex: currentPageIndex,
                            output: currentBotMessage.content,
                        }),
                    );
                }
            }
        }
    }, [roleId, messages, currentPageIndex]);

    if (!roleId) {
        return (
            <div className='flex-1 w-full flex flex-col items-center justify-center'>
                <AiOutlineLoading3Quarters className='animate-spin h-12 w-1' />
            </div>
        );
    }

    const switchPage = (pageIndex: number) => {
        if (pageIndex >= 0 && pageIndex < pages.length) {
            dispatch(setCurrentPageIndex({ userId, roleId, pageIndex }));
        }
    };

    const exportPagesToFile = () => {
        let fileContent = '';
        pages.forEach((page: PageData, index: number) => {
            fileContent += `Page ${index + 1}\nInput:\n${page.input}\nOutput:\n${page.output}\n\n`;
        });

        // 创建一个Blob对象，并设置文件类型为text/plain
        const blob = new Blob([fileContent], { type: 'text/plain' });

        // 创建一个链接元素
        const link = document.createElement('a');

        // 设置下载属性和href属性
        link.download = 'tranalation.txt';
        link.href = window.URL.createObjectURL(blob);

        // 触发下载
        link.click();

        // 清理
        window.URL.revokeObjectURL(link.href);
    };

    const onSend = async (type: string) => {
        if (pages[currentPageIndex].input?.trim()) {
            dispatch(
                updatePageOutput({
                    userId,
                    roleId,
                    pageIndex: currentPageIndex,
                    output: '',
                }),
            );
            await clearMessages({ roleId, userId });
            await sendMessage({
                id: pages[currentPageIndex].id,
                input: generateJsonInput(pages[currentPageIndex].input),
                roleId: roleId,
                userId: account?.localAccountId,
                params: {
                    ...(features?.showLanguage
                        ? {
                              language:
                                  (pages?.[currentPageIndex]?.params
                                      ?.language as string) ?? 'en',
                          }
                        : {}),
                    ...(features?.showSize
                        ? {
                              size:
                                  (pages?.[currentPageIndex]?.params
                                      ?.size as number) ?? 1000,
                          }
                        : {}),
                    ...(features?.showKeywords
                        ? {
                              keywords:
                                  (pages?.[currentPageIndex]?.params
                                      ?.keywords as string) ?? '',
                          }
                        : {}),
                    outputType: type,
                },
            } as IInput);
        }
    };

    const handleTextSelect = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const { commonAncestorContainer } = range;
            const content = commonAncestorContainer.textContent || '';

            // 检查是否整行或整段内容被选中
            const isWholeLineSelected =
                range.startOffset === 0 && range.endOffset === content.length;

            if (isWholeLineSelected) {
                const rect = range.getBoundingClientRect();
                setMenuPosition({ top: rect.top + 24, left: rect.left });
                setShowMenu(true);
            } else {
                setShowMenu(false);
            }
        } else {
            setShowMenu(false);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(
            updatePageInput({
                userId,
                roleId,
                pageIndex: currentPageIndex,
                input: value,
            }),
        );
    };

    const handleKeywordsChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const { value } = event.target;
        dispatch(
            updatePageKeywords({
                userId,
                roleId,
                pageIndex: currentPageIndex,
                keywords: value,
            }),
        );
    };

    const createNewPage = () => {
        const newPage: PageData = {
            id: uuidv1().replace(/-/g, ''),
            input: '',
            output: '',
            params: {
                ...(features?.showLanguage
                    ? {
                          language:
                              (pages?.[currentPageIndex]?.params
                                  ?.language as string) ?? 'en',
                      }
                    : {}),
                ...(features?.showSize
                    ? {
                          size:
                              (pages?.[currentPageIndex]?.params
                                  ?.size as number) ?? 100,
                      }
                    : {}),
                ...(features?.showKeywords
                    ? {
                          keywords: '',
                      }
                    : {}),
            },
        };
        dispatch(addPage({ userId, roleId, pageData: newPage }));
    };

    const deleteCurrentPage = async () => {
        await clearMessages({ roleId, userId });
        if (currentPageIndex === 0 && currentPageIndex === pages.length - 1) {
            clearCurrentPage();
        } else {
            dispatch(
                deletePage({ userId, roleId, pageIndex: currentPageIndex }),
            );
        }
    };

    const clearCurrentPage = async () => {
        const emptyPage: PageData = {
            id: uuidv1().replace(/-/g, ''),
            input: '',
            output: '',
            params: {
                ...(features?.showLanguage ? { language: 'en' } : {}),
                ...(features?.showSize ? { size: 1000 } : {}),
                ...(features?.showKeywords ? { keyword: '' } : {}),
            },
        };
        dispatch(
            updateCurrentPage({
                userId,
                roleId,
                pageData: emptyPage,
            }),
        );
    };

    const isPagesEmpty = () => {
        const currentPage = pages?.[currentPageIndex];
        const lastPage = pages?.[pages.length - 1];
        return (
            (!currentPage?.input?.trim() && !currentPage?.output?.trim()) ||
            (!lastPage?.input?.trim() && !lastPage?.output?.trim())
        );
    };

    return (
        <div className='bg-base-100 text-base-content flex flex-col flex-1 space-y-1 p-4 h-full w-full '>
            <div className='flex flex-row flex-none'>
                <h2 className='flex flex-none text-lg font-medium'>
                    {t('generation.name')}
                </h2>
                <div className='flex flex-grow justify-end'>
                    <div className='flex flex-none items-center'>
                        <button
                            type='button'
                            title={t('generation.newDescription')}
                            disabled={isPagesEmpty()}
                            className={`btn btn-primary btn-outline text-sm p-1 mr-4 ${
                                isPagesEmpty() ? 'btn-disabled' : ''
                            }`}
                            onClick={createNewPage}
                        >
                            {t('generation.new')}
                        </button>
                        <button
                            type='button'
                            title={t('generation.deleteDescription')}
                            className={`btn btn-primary btn-outline text-sm p-1 mr-4 `}
                            onClick={deleteCurrentPage}
                        >
                            {t('generation.delete')}
                        </button>

                        <button
                            type='button'
                            title={t('generation.exportDescription')}
                            className='btn btn-primary btn-outline text-sm p-1 '
                            onClick={exportPagesToFile}
                        >
                            {t('generation.export')}
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex flex-row flex-1 justify-between items-start w-full h-full overflow-y-auto'>
                <div className='flex flex-col flex-auto w-1/2 px-2'>
                    <label htmlFor='title' className='label flex flex-none'>
                        {t('generation.input')}
                    </label>
                    <input
                        name='title'
                        className={`input input-bordered flex flex-col flex-none w-full px-2 py-0 `}
                        title='title'
                        value={pages?.[currentPageIndex]?.input ?? ''}
                        placeholder={t('generation.titlePlaceholder')}
                        onChange={handleInputChange}
                    />
                    <label htmlFor='keywords' className='label flex flex-none'>
                        {t('generation.keywords')}
                    </label>
                    <textarea
                        name='keywords'
                        className={`textarea textarea-bordered flex flex-col flex-none resize-none px-2 py-0  `}
                        title='keywords'
                        rows={5}
                        value={
                            (pages?.[currentPageIndex]?.params?.[
                                'keywords'
                            ] as string) ?? ''
                        }
                        placeholder={t('generation.keywordsPlaceholder')}
                        onChange={handleKeywordsChange}
                    />
                    <div className='flex flex-row flex-none py-4'>
                        {features?.enableContent && (
                            <button
                                type='button'
                                title={t('generation.content')}
                                className='btn btn-primary btn-outline flex flex-none mr-2'
                                onClick={async () => onSend('content')}
                            >
                                {t('generation.content')}
                            </button>
                        )}
                        {features?.enableOutline && (
                            <button
                                type='button'
                                title={t('generation.outline')}
                                className='btn btn-primary btn-outline flex flex-none '
                                onClick={async () => onSend('outline')}
                            >
                                {t('generation.outline')}
                            </button>
                        )}
                    </div>
                </div>
                {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                <div
                    className='flex flex-col flex-auto justify-start items-start w-1/2 h-full overflow-y-auto px-2'
                    onMouseUp={handleTextSelect}
                >
                    {/* eslint-enable jsx-a11y/no-static-element-interactions */}
                    <Markdown
                        className={`flex flex-1 flex-col textarea textarea-bordered h-full w-full`}
                        remarkPlugins={[remarkMath, remarkGfm]}
                        rehypePlugins={[rehypeKatex]}
                        components={{
                            code(props) {
                                return (
                                    <CodeBlock
                                        {...props}
                                        customStyle={{
                                            border: '0',
                                        }}
                                    />
                                );
                            },
                            p(props) {
                                return <div {...props} />;
                            },
                            pre(props) {
                                return <pre {...props} />;
                            },
                        }}
                    >
                        {pages?.[currentPageIndex]?.output}
                    </Markdown>
                    {showMenu && (
                        <ul
                            className='absolute z-10 p-2 menu menu-md bg-base-200 rounded-box '
                            style={{
                                top: `${menuPosition.top}px`,
                                left: `${menuPosition.left}px`,
                            }}
                        >
                            <li>
                                <button>{t('generation.content')}</button>
                            </li>
                            <li>
                                <button>{t('generation.subOutline')}</button>
                            </li>
                        </ul>
                    )}
                </div>
            </div>

            <Pagination
                currentPage={currentPageIndex}
                totalPages={pages.length}
                onPageChange={switchPage}
            />
        </div>
    );
};

export default ContentGeneration;
