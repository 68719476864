// src/components/chat/ChatRoom.tsx
import { useMsal } from '@azure/msal-react';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaImages } from 'react-icons/fa6';
import { v1 as uuidv1 } from 'uuid';

import { useChat } from '../../hooks/useChat';
import type { IInput } from '../../models/ChatInput';
import { AuthorRoles } from '../../models/ChatMessage';
import type { IRoleBase } from '../../models/Role';
import { useGetRoleByIdQuery } from '../../services/sessionApi';
import { generateJsonInput } from '../../utils/format';

import ChatHistory from './ChatHistory';
import ChatInput from './ChatInput';

interface ChatRoomProps {
    roleId: string;
}

const ChatRoom: React.FC<ChatRoomProps> = ({ roleId }) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const { messages, isSendMessageError, isGetMessagesError, sendMessage } =
        useChat(roleId);
    const { data: role } = useGetRoleByIdQuery(roleId);
    const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
    const scrollViewTargetRef = useRef<HTMLDivElement>(null);
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        if (isSendMessageError || isGetMessagesError) {
            setIsTyping(false);
        }
    }, [isSendMessageError, isGetMessagesError]);

    useEffect(() => {
        if (!shouldAutoScroll) return;
        scrollViewTargetRef.current?.scrollTo(
            0,
            scrollViewTargetRef.current.scrollHeight,
        );
    }, [messages, shouldAutoScroll]);

    useEffect(() => {
        const onScroll = () => {
            if (!scrollViewTargetRef.current) return;
            const { scrollTop, scrollHeight, clientHeight } =
                scrollViewTargetRef.current;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
            setShouldAutoScroll(isAtBottom);
        };

        if (!scrollViewTargetRef.current) return;

        const currentScrollViewTarget = scrollViewTargetRef.current;

        currentScrollViewTarget.addEventListener('scroll', onScroll);
        return () => {
            currentScrollViewTarget.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        if (isTyping === true) {
            if (messages && messages.length > 0) {
                // 假设我们可以通过某种方式判断最后一条消息是否为Bot的回复
                const lastMessage = messages[messages.length - 1];
                if (
                    lastMessage.authorRole === AuthorRoles.Bot &&
                    lastMessage.content
                ) {
                    setIsTyping(false);
                }
            } else {
                setIsTyping(false);
            }
        }
    }, [messages, roleId]); // 将messages作为依赖项

    if (!roleId || !role) {
        <div className='flex-1 w-full flex flex-col items-center justify-center'>
            <AiOutlineLoading3Quarters className='animate-spin h-12 w-12' />
        </div>;
    }

    const onSend = async (input: string, images: string[] | undefined) => {
        setIsTyping(true);
        await sendMessage({
            id: uuidv1().replace(/-/g, ''),
            input: generateJsonInput(input, images),
            roleId: roleId,
            userId: account?.localAccountId,
            params: {},
        } as IInput);
    };

    return (
        account && (
            <div className='flex flex-auto flex-col overflow-auto bg-base-100 text-base-content px-2'>
                <div
                    ref={scrollViewTargetRef}
                    className='bg-base-200 text-base-content flex flex-col flex-auto overflow-auto rounded-md'
                >
                    <ChatHistory
                        messages={messages}
                        role={role as IRoleBase}
                        isTyping={isTyping}
                    />
                </div>
                <div className='flex-none flex flex-col pt-2'>
                    <ChatInput onSend={onSend} role={role} />
                </div>
            </div>
        )
    );
};

export default ChatRoom;
