import { useMsal } from '@azure/msal-react';
import React from 'react';

import type { IChatMessage } from '../../models/ChatMessage';
import { AuthorRoles } from '../../models/ChatMessage';
import type { IRoleBase } from '../../models/Role';
import { formatTime } from '../../utils/format';

import { ChatTextContent } from './ChatTextContent';

interface ChatMessageItemProps {
    message: IChatMessage;
    role: IRoleBase;
}

export const ChatMessageItem: React.FC<ChatMessageItemProps> = ({
    message,
    role,
}) => {
    const isBot = message.authorRole === AuthorRoles.Bot;
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    if (message.roleId !== role.id) return null;

    return (
        <div className='flex flex-col w-full py-2 px-4'>
            {isBot ? (
                message.content && (
                    <div className='flex flex-col w-full justify-start'>
                        <div className='flex flex-row flex-none items-start'>
                            <img
                                src={role?.icon}
                                alt={role?.title}
                                className='w-8 h-8 object-cover'
                            />
                            <span className='mx-2 text-xs font-semibold'>
                                {role?.title}
                            </span>
                            <span className='mx-1 text-xs'>
                                {formatTime(message.timestamp)}
                            </span>
                        </div>
                        <div
                            className='bg-base-300 text-base-content m-2 flex flex-col w-fit xl:max-w-[75%] items-start
                        p-2 border border-base-200 rounded-md'
                        >
                            <ChatTextContent message={message} />
                        </div>
                    </div>
                )
            ) : (
                <div className='flex flex-col w-full justify-end items-end'>
                    <div className='flex flex-none w-full justify-end'>
                        <span className='mx-1 text-xs'>
                            {formatTime(message.timestamp)}
                        </span>
                        <span className='mx-2 text-xs font-semibold'>
                            {account?.name}
                        </span>

                        <img
                            src='/icons/user.png'
                            alt={account?.name}
                            className='w-8 h-8 object-cover'
                        />
                    </div>
                    <div
                        className='bg-base-300 text-base-content m-2 flex flex-col w-fit xl:max-w-[75%] justify-start items-start
                        p-2 border border-base-200 rounded-md'
                    >
                        <ChatTextContent message={message} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatMessageItem;
