//Sidebar.tsx
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';
import React from 'react';

interface SidebarProps {
    isOpen: boolean;
    setIsOpen?: () => void;
    children?: React.ReactNode;
}

export const Sidebar: React.FC<SidebarProps> = ({
    isOpen,
    setIsOpen,
    children,
}) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent);
    const handleChevronClick = () => {
        setIsOpen?.();
    };

    return (
        <div
            className={`bg-base-200 text-base-content flex flex-col flex-none h-full overflow-y-auto box-border border-base-300 duration-300 ease-in-out border-r border-opacity-50 ${
                isOpen
                    ? `${isMobile ? 'w-screen' : 'w-48 sm:w-64 lg:w-80'}`
                    : 'w-14'
            }`}
        >
            <div className='flex flex-none justify-end items-center h-16 border-base-300 border-b'>
                <button
                    title={t(isOpen ? 'sidebar.collapse' : 'sidebar.expand')}
                    className='flex flex-row justify-end items-center bg-transparent border-0 pr-2'
                    onClick={() => handleChevronClick()}
                >
                    <ChevronRightIcon
                        className={`flex-none h-6 delay-300 pr-2 ${
                            isOpen ? 'invisible w-0 ' : 'visible w-6'
                        }`}
                        aria-hidden='true'
                    />
                    <ChevronLeftIcon
                        className={`flex-none h-6 delay-300  ${
                            isOpen ? 'visible w-6' : 'invisible w-0 '
                        }`}
                        aria-hidden='true'
                    />
                </button>
            </div>
            {children}
        </div>
    );
};

export default Sidebar;
