import type { ImageContent, TextContent } from '../models/ChatMessage';

// format.ts
export function formatTime(time: string): string {
    const now = new Date();
    const messageDate = new Date(time);
    const diff = now.getTime() - messageDate.getTime();

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const oneWeek = oneDay * 7;
    const oneYear = oneDay * 365;

    if (diff < oneDay) {
        // 如果消息是今天创建的，只显示小时，分钟和秒
        return messageDate.toLocaleTimeString();
    } else if (diff < oneWeek) {
        // 如果消息是本周创建的，只显示星期几
        return `${messageDate.toLocaleDateString('en-US', {
            weekday: 'long',
        })} ${messageDate.toLocaleTimeString()}`;
    } else if (diff < oneYear) {
        // 如果消息是今年创建的，只显示月份和日期
        return `${messageDate.toLocaleDateString(undefined, {
            month: 'long',
            day: 'numeric',
        })} ${messageDate.toLocaleTimeString()}`;
    } else {
        // 其他情况，显示年月日
        return messageDate.toLocaleDateString();
    }
}

export function joinUrls(...urls: string[]): string {
    return urls.reduce((accumulatedUrl, currentUrl) => {
        // 移除累积URL末尾的斜杠（如果有）
        const normalizedAccumulatedUrl = accumulatedUrl.replace(/\/+$/, '');
        // 移除当前URL开头的斜杠（如果有）
        const normalizedCurrentUrl = currentUrl.replace(/^\/+/, '');
        // 拼接URL，并在中间添加一个斜杠
        return `${normalizedAccumulatedUrl}/${normalizedCurrentUrl}`;
    });
}

export const generateJsonInput = (input: string, urls?: string[]) => {
    let contentArray: (TextContent | ImageContent)[] = [];
    if (input && input.trim() !== '') {
        contentArray = [{ $type: 'TextContent', Text: input }];
    }

    if (urls && urls.length > 0) {
        urls.forEach((url) => {
            contentArray.push({
                $type: 'ImageContent',
                Uri: url,
            });
        });
    }
    const jsonInput = { Items: contentArray };
    return JSON.stringify(jsonInput);
};
