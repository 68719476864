import React from 'react';

export const Admin = () => {
    return (
        <div className='flex flex-col flex-1'>
            <p>ToDo</p>
        </div>
    );
};

export default Admin;
