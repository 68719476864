// src/components/ContrentTransform.tsx

import { useMsal } from '@azure/msal-react';
import {
    AnonymousCredential,
    BlockBlobClient,
    newPipeline,
} from '@azure/storage-blob';
import mammoth from 'mammoth';
import * as pdfjs from 'pdfjs-dist';
import type { TextItem } from 'pdfjs-dist/types/src/display/api';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import Markdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { v1 as uuidv1 } from 'uuid';

import { useAnswer } from '../hooks/useAnswer';
import type { IInput } from '../models/ChatInput';
import {
    addPage,
    setCurrentPageIndex,
    updatePageInput,
    updatePageLanguage,
    updatePageOutput,
    deletePage,
    deletePageImage,
    addPageImage,
    updatePageSize,
} from '../redux/features/role/roleReducer';
import type { PageData } from '../redux/features/role/RoleState';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
    useGetDownloadUrlMutation,
    useGetUploadUrlMutation,
} from '../services/contentApi';
import { useClearMessagesMutation } from '../services/messageApi';
import { generateJsonInput } from '../utils/format';

import { CodeBlock } from './chat/CodeBlock';
import ImagePreview from './content/ImagePreview';
import Pagination from './Pagination';

interface ContentTransformProps {
    name: string;
    roleId: string;
    features?: { showLanguage?: boolean; showSize?: boolean };
}

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

const ContentTransform: React.FC<ContentTransformProps> = ({
    name,
    roleId,
    features,
}) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const userId = account?.localAccountId ?? '';
    const dispatch = useAppDispatch();

    const pages = useAppSelector(
        (state) => state.role?.[userId]?.data?.[roleId]?.pages ?? [],
    );
    const currentPageIndex = useAppSelector(
        (state) => state.role?.[userId]?.data?.[roleId]?.currentPageIndex ?? 0,
    );
    const [getUploadUrl] = useGetUploadUrlMutation();
    const pipeline = newPipeline(new AnonymousCredential());
    const [getDownloadUrl] = useGetDownloadUrlMutation();
    const { t } = useTranslation();

    const docInputRef = useRef<HTMLInputElement>(null);
    const [clearMessages] = useClearMessagesMutation();
    // 使用当前页面的messageId初始化useAnswer钩子
    const { messages, sendMessage } = useAnswer(
        roleId,
        pages?.[currentPageIndex]?.id ?? '',
    );
    const [uploadProgress, setUploadProgress] = useState<number>(0);

    React.useEffect(() => {
        if (!pages || pages.length === 0) {
            createNewPage();
        }
    }, [roleId, pages.length]);

    React.useEffect(() => {
        if (messages && messages.length > 0) {
            {
                const currentBotMessage = messages.find(
                    (msg) => msg.id === pages[currentPageIndex]?.id + '-bot',
                );
                if (currentBotMessage) {
                    dispatch(
                        updatePageOutput({
                            userId,
                            roleId,
                            pageIndex: currentPageIndex,
                            output: currentBotMessage.content,
                        }),
                    );
                }
            }
        }
    }, [roleId, messages, currentPageIndex]);

    if (!roleId) {
        return (
            <div className='flex-1 w-full flex flex-col items-center justify-center'>
                <AiOutlineLoading3Quarters className='animate-spin h-12 w-12' />
            </div>
        );
    }

    const switchPage = (pageIndex: number) => {
        if (pageIndex >= 0 && pageIndex < pages.length) {
            dispatch(setCurrentPageIndex({ userId, roleId, pageIndex }));
        }
    };

    const handleRemoveImage = (image: string) => {
        dispatch(
            deletePageImage({
                userId,
                roleId,
                pageIndex: currentPageIndex,
                image,
            }),
        );
    };

    const loadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const content = e.target?.result;
                if (content instanceof ArrayBuffer) {
                    let textContent = '';
                    if (file.type === 'application/pdf') {
                        // 使用pdf.js解析PDF文件
                        const loadingTask = pdfjs.getDocument({
                            data: content,
                        });

                        const pdf = await loadingTask.promise;
                        for (let i = 1; i <= pdf.numPages; i++) {
                            const page = await pdf.getPage(i);
                            const textContentPage = await page.getTextContent();
                            textContent += textContentPage.items
                                .map((item) => (item as TextItem).str)
                                .join(' ');
                        }
                    } else if (
                        file.type ===
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    ) {
                        // 使用mammoth.js解析DOCX文件
                        try {
                            const result = await mammoth.extractRawText({
                                arrayBuffer: content,
                            });
                            textContent = result.value;
                        } catch (error) {
                            console.error('Error reading docx file:', error);
                        }
                    } else {
                        const decoder = new TextDecoder('utf-8');
                        // 使用decode方法将ArrayBuffer转换为字符串
                        textContent = decoder.decode(content);
                    }
                    // 更新input,output的内容
                    dispatch(
                        updatePageInput({
                            userId,
                            roleId,
                            pageIndex: currentPageIndex,
                            input: textContent,
                        }),
                    );
                    dispatch(
                        updatePageOutput({
                            userId,
                            roleId,
                            pageIndex: currentPageIndex,
                            output: '',
                        }),
                    );
                }
            };
            reader.readAsArrayBuffer(file);
            if (docInputRef.current) {
                docInputRef.current.value = '';
            }
        }
    };

    const handlePaste = async (
        event: React.ClipboardEvent<HTMLTextAreaElement>,
    ) => {
        const items = event.clipboardData.items;
        for (const item of items) {
            if (item.type.indexOf('image') === 0) {
                const file = item.getAsFile();
                if (file) {
                    setUploadProgress(1);
                    const fileName = uuidv1().replace(/-/g, '');
                    const url = await getUploadUrl({
                        filePath: fileName,
                        period: 60,
                    }).unwrap();
                    setUploadProgress(10);
                    if (url) {
                        const blockBlobClient = new BlockBlobClient(
                            url,
                            pipeline,
                        );

                        await blockBlobClient.uploadData(file, {
                            blockSize: 2 * 1024 * 1024, // 4MB block size
                            concurrency: navigator.hardwareConcurrency, // 20 concurrency
                            onProgress: (ev: any) => {
                                const progress =
                                    10 +
                                    ((ev?.loadedBytes ?? 0) /
                                        (file.size ?? ev?.loadedBytes + 1)) *
                                        80;
                                setUploadProgress(progress);
                            },
                        });
                        setUploadProgress(90);
                        const downloadUrl = await getDownloadUrl({
                            filePath: fileName,
                            period: 60 * 240,
                        }).unwrap();
                        setUploadProgress(100);
                        if (downloadUrl) {
                            dispatch(
                                addPageImage({
                                    userId,
                                    roleId,
                                    pageIndex: currentPageIndex,
                                    image: downloadUrl,
                                }),
                            );
                        }
                    }
                }
            }
        }
    };

    const exportPagesToFile = () => {
        let fileContent = '';
        pages.forEach((page: PageData, index: number) => {
            fileContent += `Page ${index + 1}\nInput:\n${page.input}\nOutput:\n${page.output}\n\n`;
        });

        // 创建一个Blob对象，并设置文件类型为text/plain
        const blob = new Blob([fileContent], { type: 'text/plain' });

        // 创建一个链接元素
        const link = document.createElement('a');

        // 设置下载属性和href属性
        link.download = 'tranalation.txt';
        link.href = window.URL.createObjectURL(blob);

        // 触发下载
        link.click();

        // 清理
        window.URL.revokeObjectURL(link.href);
    };

    const onSend = async () => {
        if (
            pages[currentPageIndex].input?.trim() ||
            (pages[currentPageIndex]?.params?.['images'] as string[])?.length >
                0
        ) {
            await clearMessages({ roleId, userId });
            await sendMessage({
                id: pages[currentPageIndex].id,
                input: generateJsonInput(
                    pages[currentPageIndex].input,
                    pages[currentPageIndex].params?.['images'] as string[],
                ),
                roleId: roleId,
                userId: account?.localAccountId,
                params: {
                    ...(features?.showLanguage
                        ? {
                              language:
                                  (pages?.[currentPageIndex]?.params
                                      ?.language as string) ?? 'en',
                          }
                        : {}),
                    ...(features?.showSize
                        ? {
                              size:
                                  (pages?.[currentPageIndex]?.params
                                      ?.size as number) ?? 100,
                          }
                        : {}),
                },
            } as IInput);
        }
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const { value } = event.target;
        dispatch(
            updatePageInput({
                userId,
                roleId,
                pageIndex: currentPageIndex,
                input: value,
            }),
        );
    };

    const handleLanguageChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const { value } = event.target;
        dispatch(
            updatePageLanguage({
                userId,
                roleId,
                pageIndex: currentPageIndex,
                language: value,
            }),
        );
    };

    const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(
            updatePageSize({
                userId,
                roleId,
                pageIndex: currentPageIndex,
                size: Number(value),
            }),
        );
    };

    const createNewPage = () => {
        const newPage: PageData = {
            id: uuidv1().replace(/-/g, ''),
            input: '',
            output: '',
            params: {
                ...(features?.showLanguage
                    ? {
                          language:
                              (pages?.[currentPageIndex]?.params
                                  ?.language as string) ?? 'en',
                      }
                    : {}),
                ...(features?.showSize
                    ? {
                          size:
                              (pages?.[currentPageIndex]?.params
                                  ?.size as number) ?? 100,
                      }
                    : {}),
                images: [],
            },
        };
        dispatch(addPage({ userId, roleId, pageData: newPage }));
    };

    const deleteCurrentPage = async () => {
        await clearMessages({ roleId, userId });
        if (currentPageIndex === 0 && currentPageIndex === pages.length - 1) {
            dispatch(
                updatePageInput({
                    userId,
                    roleId,
                    pageIndex: currentPageIndex,
                    input: '',
                }),
            );
            dispatch(
                updatePageOutput({
                    userId,
                    roleId,
                    pageIndex: currentPageIndex,
                    output: '',
                }),
            );
        } else {
            dispatch(
                deletePage({ userId, roleId, pageIndex: currentPageIndex }),
            );
        }
    };

    const isPagesEmpty = () => {
        const currentPage = pages?.[currentPageIndex];
        const lastPage = pages?.[pages.length - 1];
        return (
            (!currentPage?.input?.trim() && !currentPage?.output?.trim()) ||
            (!lastPage?.input?.trim() && !lastPage?.output?.trim())
        );
    };

    return (
        <div className='bg-base-100 text-base-content flex flex-col h-full space-y-1 p-4'>
            <div className='flex flex-row flex-none w-full'>
                <h2 className='label flex flex-none text-lg'>
                    {t('transform.input')}
                </h2>
                <div className='flex flex-row flex-none justify-start '>
                    <button
                        className='btn btn-ghost btn-primary flex items-center justify-center px-1'
                        onClick={() => docInputRef.current?.click()}
                        title={t('transform.load')}
                        aria-label={t('transform.load')}
                    >
                        <FiDownload className='w-5 h-5' />
                    </button>
                    <input
                        type='file'
                        className='hidden'
                        ref={docInputRef}
                        onChange={loadFile}
                        accept='.txt,.pdf,.docx'
                    />
                </div>
                <div className='flex flex-grow justify-end'>
                    <div className='flex flex-none items-center'>
                        <button
                            type='button'
                            title={t('transform.newDescription')}
                            disabled={isPagesEmpty()}
                            className={`btn btn-primary btn-outline text-sm px-2 py-0  mr-4  ${
                                isPagesEmpty() ? 'btn-disabled' : ''
                            }`}
                            onClick={createNewPage}
                        >
                            {t('transform.new')}
                        </button>
                        <button
                            type='button'
                            title={t('transform.deleteDescription')}
                            className={`btn btn-primary btn-outline text-sm px-2 py-0 mr-4`}
                            onClick={deleteCurrentPage}
                        >
                            {t('transform.delete')}
                        </button>

                        <button
                            type='button'
                            title={t('transform.exportDescription')}
                            className='btn btn-primary btn-outline text-sm px-2 py-0'
                            onClick={exportPagesToFile}
                        >
                            {t('transform.export')}
                        </button>
                    </div>
                </div>
            </div>

            <div className='flex flex-row flex-1 items-center border border-solid rounded-lg p-2 '>
                <div className='flex flex-col flex-1 h-[40vh] max-h-[40vh] overflow-y-auto justify-start '>
                    {uploadProgress > 0 && uploadProgress < 100 && (
                        <progress
                            className='progress progress-primary flex flex-none w-56'
                            value={uploadProgress}
                            max='100'
                        ></progress>
                    )}
                    <div className='flex flex-col flex-none max-h-max justify-start'>
                        {(pages[currentPageIndex]?.params['images'] as string[])
                            ?.length > 0 && (
                            <ImagePreview
                                images={
                                    pages?.[currentPageIndex]?.params[
                                        'images'
                                    ] as string[]
                                }
                                onRemove={handleRemoveImage}
                            />
                        )}
                    </div>
                    <textarea
                        className={`textarea textarea-ghost flex flex-col flex-none w-full h-full border-0 focus:outline-0 max-h-max overflow-y-auto resize-none px-2 py-0 `}
                        title='input text'
                        value={pages?.[currentPageIndex]?.input ?? ''}
                        placeholder={
                            (
                                pages?.[currentPageIndex]?.params[
                                    'images'
                                ] as string[]
                            )?.length > 0
                                ? ''
                                : t('transform.inputPlaceholder')
                        }
                        onChange={handleInputChange}
                        onPaste={handlePaste}
                    />
                </div>
                <button
                    type='button'
                    title={t('transform.' + name)}
                    className='btn btn-primary btn-outline p-2 ml-2 '
                    onClick={onSend}
                >
                    {t('transform.' + name)}
                </button>
            </div>

            <div className='flex flex-none flex-row justify-between items-center'>
                <h2 className='label text-lg'>{t('transform.output')}</h2>
                {features?.showLanguage && (
                    <div className='flex flex-1 flex-row justify-end items-center'>
                        <label htmlFor='language' className='mr-2 text-sm'>
                            {t('transform.language')}
                        </label>
                        <select
                            name='language'
                            title='Select language'
                            id='language'
                            className='select select-bordered flex-1 text-sm max-w-fit py-0'
                            onChange={handleLanguageChange}
                            value={
                                pages?.[currentPageIndex]?.params[
                                    'language'
                                ] as string
                            }
                        >
                            <option value='en'>English</option> {/*英语*/}
                            <option value='zh-CN'>中文简体</option>{' '}
                            <option value='zh-TW'>中文繁體</option>{' '}
                            <option value='ja'>日本語</option> {/*日语*/}
                            <option value='ko'>한글</option> {/*韩语*/}
                            <option value='fr'>Français</option> {/*法语*/}
                            <option value='de'>Deutsch</option> {/*德语*/}
                            <option value='es'>Español</option> {/*西班牙语*/}
                            <option value='it'>Italiano</option> {/*意大利语*/}
                            <option value='ru'>Русский</option> {/*俄语*/}
                        </select>
                    </div>
                )}
                {features?.showSize && (
                    <div className='flex flex-1 flex-row justify-end items-center'>
                        <label htmlFor='summarySize' className='label mr-2 '>
                            {t('transform.length')}
                        </label>

                        <input
                            type='number'
                            title='Summary Size'
                            name='summarySize'
                            id='summarySize'
                            min={1}
                            max={2000}
                            value={
                                (pages[currentPageIndex]?.params?.[
                                    'size'
                                ] as number) ?? 100
                            }
                            onChange={handleSizeChange}
                            className='input input-bordered flex-1 text-sm max-w-fit'
                        />
                    </div>
                )}
            </div>
            <div className='flex flex-1 flex-grow overflow-y-auto items-start border border-solid rounded-lg p-2 '>
                <Markdown
                    className={`flex flex-1 flex-col border-0 h-full w-full`}
                    remarkPlugins={[remarkMath, remarkGfm]}
                    rehypePlugins={[rehypeKatex]}
                    components={{
                        code(props) {
                            return (
                                <CodeBlock
                                    {...props}
                                    customStyle={{
                                        border: '0',
                                    }}
                                />
                            );
                        },
                        p(props) {
                            return <div {...props} />;
                        },
                        pre(props) {
                            return <pre {...props} />;
                        },
                    }}
                >
                    {pages?.[currentPageIndex]?.output}
                </Markdown>
            </div>
            <Pagination
                currentPage={currentPageIndex}
                totalPages={pages.length}
                onPageChange={switchPage}
            />
        </div>
    );
};

export default ContentTransform;
