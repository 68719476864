// rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';

import { chatApiSlice } from '../services/chatApi';
import { contentApiSlice } from '../services/contentApi';
import { messageApiSlice } from '../services/messageApi';
import { sessionApiSlice } from '../services/sessionApi';

import appReducer from './features/app/appReducer';
import roleReducer from './features/role/roleReducer';
const rootReducer = combineReducers({
    [sessionApiSlice.reducerPath]: sessionApiSlice.reducer,
    [chatApiSlice.reducerPath]: chatApiSlice.reducer,
    [messageApiSlice.reducerPath]: messageApiSlice.reducer,
    [contentApiSlice.reducerPath]: contentApiSlice.reducer,
    app: appReducer,
    role: roleReducer,
});

export default rootReducer;
