import {
    EventType,
    type AccountInfo,
    type AuthenticationResult,
    type EventMessage,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { msalInstance } from './authConfig';
import './index.css';
import './i18n';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root')!);

export const allowedTenantDomains: string[] =
    import.meta.env.VITE_ALLOWED_DOMAINS?.split(',').map((item: string) =>
        item.trim(),
    );

//only the users from specified domain are allowed.
const getAllowedAccount = (accounts: AccountInfo[] | AccountInfo | null) => {
    //check if the account is an array, if yes, map the array and return the first account from the allowed domain.
    //if not, check if the account is from the allowed domain, if yes, return the account, if not, return null.
    if (Array.isArray(accounts)) {
        for (const account of accounts) {
            const tenantDomain = account?.username
                ?.split('@')?.[1]
                ?.toLowerCase();
            if (tenantDomain && allowedTenantDomains.includes(tenantDomain)) {
                return account;
            }
        }
    } else {
        const tenantDomain = accounts?.username?.split('@')?.[1]?.toLowerCase();
        if (tenantDomain && allowedTenantDomains.includes(tenantDomain)) {
            return accounts;
        }
    }
    return null;
};

const initialize = async () => {
    await msalInstance.initialize();
    await msalInstance.handleRedirectPromise();

    // Set active account on page load
    msalInstance.setActiveAccount(
        getAllowedAccount(msalInstance.getActiveAccount()),
    );

    // Default to using the first allowed account if no account is active on page load
    if (
        !msalInstance.getActiveAccount() &&
        msalInstance.getAllAccounts().length > 0
    ) {
        msalInstance.setActiveAccount(
            getAllowedAccount(msalInstance.getAllAccounts()),
        );
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback((event: EventMessage) => {
        if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS) &&
            event.payload
        ) {
            const payload = event.payload as AuthenticationResult;
            if (payload.account) {
                msalInstance.setActiveAccount(
                    getAllowedAccount(payload.account),
                );
            }
        }
    });

    if (process.env.NODE_ENV === 'development') {
        // Prepare MSW in a Service Worker
        import('../mocks/browser')
            .then(({ worker }) => {
                worker.start();
            })
            // Launched mock server, and then start client React app
            .then(() =>
                root.render(
                    <React.StrictMode>
                        <MsalProvider instance={msalInstance}>
                            <ReduxStoreProvider store={store}>
                                <BrowserRouter>
                                    <App />
                                </BrowserRouter>
                            </ReduxStoreProvider>
                        </MsalProvider>
                    </React.StrictMode>,
                ),
            );
    } else {
        // Production
        root.render(
            <React.StrictMode>
                <MsalProvider instance={msalInstance}>
                    <ReduxStoreProvider store={store}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </ReduxStoreProvider>
                </MsalProvider>
            </React.StrictMode>,
        );
    }
};

initialize().catch((error) => {
    console.error('Error initializing app', error);
});
