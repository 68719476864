// src/services/contentApi.ts
import { createApi } from '@reduxjs/toolkit/query/react';

import type { IFile } from '../models/Content';

import { axiosBaseQuery } from './baseQuery';

interface FileCreateRequest {
    name: string;
    content: string;
    roleId: string;
    isPersonal: boolean;
}

interface ContentUploadRequest {
    file: File;
    roleId: string;
    isPersonal: boolean;
}

interface ContentDownloadRequest {
    id: string;
    name?: string | undefined;
    roleId: string;
}

interface ContentDeleteRequest {
    id: string;
    name?: string | undefined;
    roleId: string;
}

interface ContentSearchRequest {
    content: string;
    roleId: string;
}

// 创建RTK Query API服务
export const contentApiSlice = createApi({
    reducerPath: 'contentApi',
    baseQuery: axiosBaseQuery({ baseUrl: '' }),
    tagTypes: ['Content'],
    endpoints: (builder) => ({
        getFilesByRoleId: builder.query<
            IFile[],
            { roleId: string; isPersonal: boolean }
        >({
            query: ({ roleId, isPersonal }) => ({
                url: `/Content/${roleId}?isPersonal=${isPersonal}`,
                method: 'GET',
            }),
            transformResponse: (response: IFile[] | '') => {
                // 检查响应状态码是否为204 No Content
                if (response === '') {
                    // 如果是204 No Content，则返回一个空数组
                    return [];
                }
                // 否则返回原始响应数据
                return response;
            },
            providesTags: [{ type: 'Content', id: 'ALL' }],
        }),
        createFile: builder.mutation<void, FileCreateRequest>({
            query: (request) => ({
                url: '/Content/create',
                method: 'POST',
                data: request,
            }),
            invalidatesTags: [{ type: 'Content', id: 'ALL' }],
        }),
        uploadFile: builder.mutation<void, ContentUploadRequest>({
            query: (body) => {
                const formData = new FormData();
                formData.append('file', body.file, body.file.name);
                formData.append('roleId', body.roleId);
                formData.append('isPersonal', body.isPersonal.toString());
                return {
                    url: '/Content/upload',
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                };
            },
            invalidatesTags: [{ type: 'Content', id: 'ALL' }],
        }),
        getUploadUrl: builder.mutation<
            string,
            { filePath: string; period: number }
        >({
            query: (request) => ({
                url: `/Content/getuploaduri?fileName=${encodeURIComponent(request.filePath)}&period=${request.period}`,
                method: 'GET',
            }),
        }),
        getDownloadUrl: builder.mutation<
            string,
            { filePath: string; period: number }
        >({
            query: (request) => ({
                url: `/Content/getdownloaduri?fileName=${encodeURIComponent(request.filePath)}&period=${request.period}`,
                method: 'GET',
            }),
        }),
        downloadFile: builder.mutation<Blob, ContentDownloadRequest>({
            query: (request) => ({
                url: '/Content/download',
                method: 'POST',
                data: request,
                responseType: 'blob',
            }),
            //transformResponse: (response: any) => response.blob(),
            invalidatesTags: [{ type: 'Content', id: 'ALL' }],
        }),
        deleteFile: builder.mutation<void, ContentDeleteRequest>({
            query: (request) => ({
                url: '/Content',
                method: 'Delete',
                data: request,
            }),
            invalidatesTags: [{ type: 'Content', id: 'ALL' }],
        }),
        searchContent: builder.query<void, ContentSearchRequest>({
            query: (request) => ({
                url: '/Content/search',
                method: 'POST',
                data: request,
            }),
        }),
    }),
});

// // 导出hooks
export const {
    useCreateFileMutation,
    useUploadFileMutation,
    useDownloadFileMutation,
    useDeleteFileMutation,
    useGetFilesByRoleIdQuery,
    useSearchContentQuery,
    useGetUploadUrlMutation,
    useGetDownloadUrlMutation,
} = contentApiSlice;
