//appSlice.ts

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AppState } from './AppState';
import { initialState } from './AppState';

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setMaintenance: (state: AppState, action: PayloadAction<boolean>) => {
            state.isMaintenance = action.payload;
        },
        // setCurrentRoleId: (
        //     state: AppState,
        //     action: PayloadAction<{ roleId: string; userId: string }>,
        // ) => {
        //     const { roleId, userId } = action.payload;
        //     if (!state.currentRoleId) {
        //         state.currentRoleId = {};
        //     }
        //     state.currentRoleId[userId] = roleId;
        // },
    },
});

export const { setMaintenance } = appSlice.actions;

export default appSlice.reducer;
