// src/services/chatApi.ts
import { createApi } from '@reduxjs/toolkit/query/react';

import type { IChatInput } from '../models/ChatInput';
import { AuthorRoles, ChatMessageType } from '../models/ChatMessage';
import type {
    ImageContent,
    TextContent,
    IChatMessage,
} from '../models/ChatMessage';

import { axiosBaseQuery } from './baseQuery';

// const convertImageToBase64 = async (file: File): Promise<string> => {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();

//         reader.onload = () => {
//             // 当读取操作成功完成时调用
//             const result = reader.result;
//             if (typeof result === 'string') {
//                 resolve(result);
//             } else {
//                 reject(new Error('FileReader did not return a string.'));
//             }
//         };

//         reader.onerror = () => {
//             // 当读取操作发生错误时调用
//             reader.abort();
//             reject(new Error('Problem parsing input file.'));
//         };

//         // 读取文件内容为 Data URL (base64 编码的字符串)
//         reader.readAsDataURL(file);
//     });
// };

const generateJsonInput = (input: string, files: File[] | undefined) => {
    if (files && files.length > 0) {
        const contentArray: (TextContent | ImageContent)[] = [
            { $type: 'TextContent', Text: input },
        ];

        files.forEach((file) => {
            contentArray.push({
                $type: 'ImageContent',
                Uri: URL.createObjectURL(file),
            });
        });
        const jsonInput = { content: contentArray };
        return JSON.stringify(jsonInput);
    }
    return input;
};

// export const generateJsonInputWithData = async (
//     input: string,
//     files: File[] | undefined,
// ) => {
//     if (files && files.length > 0) {
//         const contentArray: (TextContent | ImageContent)[] = [
//             { $type: 'TextContent', Text: input },
//         ];

//         files.forEach((file) => {
//             contentArray.push({
//                 $type: 'ImageContent',
//                 Uri: URL.createObjectURL(file),
//             });
//         });
//         const jsonInput = { content: contentArray };
//         return JSON.stringify(jsonInput);
//     }
//     return input;
// };

export const chatApiSlice = createApi({
    reducerPath: 'chatApi',
    baseQuery: axiosBaseQuery({ baseUrl: '' }),
    tagTypes: ['Message'],
    //keepUnusedDataFor: 60000,
    endpoints: (builder) => ({
        sendMessage: builder.mutation<void, IChatInput>({
            query: (newRequest) => {
                const formData = new FormData();
                formData.append('id', newRequest.id);
                if (newRequest.input) {
                    formData.append('input', newRequest.input);
                }
                formData.append('timestamp', newRequest.timestamp);
                formData.append('roleId', newRequest.roleId);
                if (newRequest.language) {
                    formData.append('language', newRequest.language);
                }
                if (newRequest.images) {
                    newRequest.images.forEach((image) => {
                        formData.append(`images`, image);
                    });
                }

                if (newRequest.size) {
                    formData.append('size', newRequest.size.toString());
                }
                // 处理多文件上传
                if (newRequest.files) {
                    newRequest.files.forEach((file) => {
                        formData.append(`files`, file, file.name);
                    });
                }
                return {
                    url: `/Messages/send`,
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                };
            },
            // 这里不能添加invalidatesTags, 因为服务端是通过SignalR推送消息的，所以返回的时候数据还没准备好
            async onQueryStarted(newRequest, { dispatch, queryFulfilled }) {
                // 乐观更新：先添加消息到缓存
                const patchResultForSingle = dispatch(
                    chatApiSlice.util.updateQueryData(
                        'getMessagesById',
                        {
                            messageId: newRequest.id,
                        },
                        (draft) => {
                            const message: IChatMessage = {
                                id: newRequest.id,
                                roleId: newRequest.roleId,
                                content: generateJsonInput(
                                    newRequest.input,
                                    newRequest.files,
                                ),
                                type: ChatMessageType.Message,
                                authorRole: AuthorRoles.User,
                                timestamp: newRequest.timestamp,
                            };
                            draft.push(message);
                        },
                    ),
                );

                const patchResultForAll = dispatch(
                    chatApiSlice.util.updateQueryData(
                        'getMessages',
                        {
                            roleId: newRequest.roleId,
                            userId: newRequest.userId,
                        },
                        (draft) => {
                            const message: IChatMessage = {
                                id: newRequest.id,
                                roleId: newRequest.roleId,
                                content: generateJsonInput(
                                    newRequest.input,
                                    newRequest.files,
                                ),
                                type: ChatMessageType.Message,
                                authorRole: AuthorRoles.User,
                                timestamp: newRequest.timestamp,
                            };
                            draft.push(message);
                        },
                    ),
                );

                // 等待mutation完成
                queryFulfilled.catch(() => {
                    // 如果请求失败，则回滚缓存更新
                    patchResultForSingle.undo();
                    patchResultForAll.undo();
                });
            },
        }),
        getMessages: builder.query<
            IChatMessage[],
            { roleId: string; userId: string }
        >({
            query: ({ roleId }) => ({
                url: `/Messages/role/${roleId}`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Message', id: 'ALL' }],
        }),
        getMessagesById: builder.query<IChatMessage[], { messageId: string }>({
            query: ({ messageId }) => ({
                url: `/Messages/${messageId}`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Message', id: 'ALL' }],
        }),
        deleteMessagesById: builder.mutation<
            void,
            { roleId: string; userId: string; messageId: string }
        >({
            query: ({ messageId }) => ({
                url: `/Messages/${messageId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Message', id: 'ALL' }],
            // 使用onQueryStarted来更新缓存
            onQueryStarted(
                { roleId, userId, messageId },
                { dispatch, queryFulfilled },
            ) {
                // 等待mutation完成
                queryFulfilled.then(() => {
                    // 更新缓存中的消息列表
                    dispatch(
                        chatApiSlice.util.updateQueryData(
                            'getMessages',
                            { roleId, userId },
                            (draft) => {
                                // 找到并移除指定ID的消息
                                return draft.filter(
                                    (message) =>
                                        !message.id.startsWith(messageId),
                                );
                            },
                        ),
                    );
                    dispatch(
                        chatApiSlice.util.updateQueryData(
                            'getMessagesById',
                            { messageId },
                            (draft) => {
                                // 找到并移除指定ID的消息
                                return draft.filter(
                                    (message) =>
                                        !message.id.startsWith(messageId),
                                );
                            },
                        ),
                    );
                });
            },
        }),
        clearMessages: builder.mutation<
            void,
            { roleId: string; userId: string }
        >({
            query: ({ roleId }) => ({
                url: `/Messages/role/${roleId}`,
                method: 'DELETE',
            }),
            // 使用onQueryStarted来更新缓存
            invalidatesTags: [{ type: 'Message', id: 'ALL' }],
            async onQueryStarted(
                { roleId, userId },
                { dispatch, queryFulfilled },
            ) {
                // 先乐观地清空本地缓存
                dispatch(
                    chatApiSlice.util.updateQueryData(
                        'getMessages',
                        { roleId, userId },
                        () => [],
                    ),
                );
                try {
                    // 等待服务器端操作完成
                    await queryFulfilled;
                } catch {}
            },
        }),
    }),
});
export const {
    useSendMessageMutation,
    useGetMessagesQuery,
    useGetMessagesByIdQuery,
    useDeleteMessagesByIdMutation,
    useClearMessagesMutation,
} = chatApiSlice;
