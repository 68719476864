//authConfig.ts

import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const method: string = 'redirect';

export const enum AuthType {
    None = 'None',
    AAD = 'AzureAd',
    AADB2C = 'AzureAdB2C',
}
export const authType = AuthType.AAD;

export const msalConfig = {
    auth: {
        clientId: import.meta.env.VITE_FRONTEND_CLIENT_ID,
        authority: import.meta.env.VITE_LOGIN_URI,
        redirectUri: import.meta.env.VITE_APP_URI,
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
        loadFrameTimeout: 15000,
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [
        `api://${import.meta.env.VITE_BACKEND_CLIENT_ID}/access_as_user`,
        'openid',
        'offline_access',
        'profile',
    ],
    extraScopesToConsent: ['User.ReadBasic'], // MS Graph scopes required for loading user information
};

export const msalInstance = new PublicClientApplication(msalConfig);
