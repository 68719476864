// src/services/chatApi.ts
import { createApi } from '@reduxjs/toolkit/query/react';

import type { IInput } from '../models/ChatInput';
import { AuthorRoles, ChatMessageType } from '../models/ChatMessage';
import type { IChatMessage } from '../models/ChatMessage';

import { axiosBaseQuery } from './baseQuery';

export const messageApiSlice = createApi({
    reducerPath: 'messageApi',
    baseQuery: axiosBaseQuery({ baseUrl: '' }),
    tagTypes: ['Message'],
    //keepUnusedDataFor: 60000,
    endpoints: (builder) => ({
        answerMessage: builder.mutation<void, IInput>({
            query: (newRequest) => {
                const { userId, ...requestWithoutUserId } = newRequest;
                return {
                    url: `/Messages/respond`,
                    method: 'POST',
                    data: requestWithoutUserId,
                };
            },
            // 这里不能添加invalidatesTags, 因为服务端是通过SignalR推送消息的，所以返回的时候数据还没准备好
            async onQueryStarted(newRequest, { dispatch, queryFulfilled }) {
                // 乐观更新：先添加消息到缓存
                const patchResultForSingle = dispatch(
                    messageApiSlice.util.updateQueryData(
                        'getMessagesById',
                        {
                            messageId: newRequest.id,
                        },
                        (draft) => {
                            if (!draft) {
                                draft = []; // Initialize draft as an empty array if it's undefined
                            }
                            const message: IChatMessage = {
                                id: newRequest.id,
                                roleId: newRequest.roleId,
                                content: newRequest.input,
                                type: ChatMessageType.Message,
                                authorRole: AuthorRoles.User,
                                timestamp: new Date().toISOString(),
                            };
                            draft.push(message);
                        },
                    ),
                );
                // 等待mutation完成
                queryFulfilled.catch(() => {
                    // 如果请求失败，则回滚缓存更新
                    patchResultForSingle.undo();
                });
            },
        }),
        chatMessage: builder.mutation<void, IInput>({
            query: (newRequest) => {
                const { userId, ...requestWithoutUserId } = newRequest;
                return {
                    url: `/Messages/respond`,
                    method: 'POST',
                    data: requestWithoutUserId,
                };
            },
            // 这里不能添加invalidatesTags, 因为服务端是通过SignalR推送消息的，所以返回的时候数据还没准备好
            async onQueryStarted(newRequest, { dispatch, queryFulfilled }) {
                // 乐观更新：先添加消息到缓存
                const patchResultForAll = dispatch(
                    messageApiSlice.util.updateQueryData(
                        'getMessages',
                        {
                            roleId: newRequest.roleId,
                            userId: newRequest.userId,
                        },
                        (draft) => {
                            if (!draft) {
                                draft = []; // Initialize draft as an empty array if it's undefined
                            }
                            const message: IChatMessage = {
                                id: newRequest.id,
                                roleId: newRequest.roleId,
                                content: newRequest.input,
                                type: ChatMessageType.Message,
                                authorRole: AuthorRoles.User,
                                timestamp: new Date().toISOString(),
                            };
                            draft.push(message);
                        },
                    ),
                );

                // 等待mutation完成
                queryFulfilled.catch(() => {
                    // 如果请求失败，则回滚缓存更新
                    patchResultForAll.undo();
                });
            },
        }),
        generateContent: builder.mutation<void, IInput>({
            query: (newRequest) => {
                const { userId, ...requestWithoutUserId } = newRequest;
                return {
                    url: `/Messages/respond`,
                    method: 'POST',
                    data: requestWithoutUserId,
                };
            },
        }),
        getMessages: builder.query<
            IChatMessage[],
            { roleId: string; userId: string }
        >({
            query: ({ roleId }) => ({
                url: `/Messages/role/${roleId}`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Message', id: 'ALL' }],
        }),
        getMessagesById: builder.query<IChatMessage[], { messageId: string }>({
            query: ({ messageId }) => ({
                url: `/Messages/${messageId}`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Message', id: 'ALL' }],
        }),
        deleteMessagesById: builder.mutation<
            void,
            { roleId: string; userId: string; messageId: string }
        >({
            query: ({ messageId }) => ({
                url: `/Messages/${messageId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Message', id: 'ALL' }],
            // 使用onQueryStarted来更新缓存
            onQueryStarted(
                { roleId, userId, messageId },
                { dispatch, queryFulfilled },
            ) {
                // 等待mutation完成
                queryFulfilled.then(() => {
                    // 更新缓存中的消息列表
                    dispatch(
                        messageApiSlice.util.updateQueryData(
                            'getMessages',
                            { roleId, userId },
                            (draft) => {
                                if (!draft) {
                                    draft = []; // Initialize draft as an empty array if it's undefined
                                }
                                return draft.filter(
                                    (message) =>
                                        !message.id.startsWith(messageId),
                                );
                            },
                        ),
                    );
                    dispatch(
                        messageApiSlice.util.updateQueryData(
                            'getMessagesById',
                            { messageId },
                            (draft) => {
                                if (!draft) {
                                    draft = []; // Initialize draft as an empty array if it's undefined
                                }
                                return draft.filter(
                                    (message) =>
                                        !message.id.startsWith(messageId),
                                );
                            },
                        ),
                    );
                });
            },
        }),
        clearMessages: builder.mutation<
            void,
            { roleId: string; userId: string }
        >({
            query: ({ roleId }) => ({
                url: `/Messages/role/${roleId}`,
                method: 'DELETE',
            }),
            // 使用onQueryStarted来更新缓存
            invalidatesTags: [{ type: 'Message', id: 'ALL' }],
            async onQueryStarted(
                { roleId, userId },
                { dispatch, queryFulfilled },
            ) {
                // 先乐观地清空本地缓存
                dispatch(
                    messageApiSlice.util.updateQueryData(
                        'getMessages',
                        { roleId, userId },
                        () => [],
                    ),
                );
                try {
                    // 等待服务器端操作完成
                    await queryFulfilled;
                } catch {}
            },
        }),
    }),
});
export const {
    useAnswerMessageMutation,
    useChatMessageMutation,
    useGetMessagesQuery,
    useGetMessagesByIdQuery,
    useDeleteMessagesByIdMutation,
    useClearMessagesMutation,
} = messageApiSlice;
