/**
 * Role of the author of a chat message. It's a copy of AuthorRoles in the API C# code.
 */
export enum AuthorRoles {
    // The current user of the chat.
    User = 'User',

    // The bot.
    Bot = 'Bot',

    // The participant who is not the current user nor the bot of the chat.
    Participant = 'Participant',
}

/**
 * Type of the chat message. A copy of ChatMessageType in the API C# code.
 */
export enum ChatMessageType {
    // A message containing text
    Message = 'Message',

    // A message showing an uploaded document
    Document = 'Document',

    //A message for an error response
    Error = 'Error',
}

export interface TextContent {
    $type: 'TextContent';
    Text: string;
    ModelId?: string;
    Metadata?: string;
}

export interface ImageContent {
    $type: 'ImageContent';
    Uri: string;
    Data?: string;
    ModelId?: string;
    Metadata?: string;
}

/**
 * States for RLHF
 */
export enum UserFeedback {
    Unknown = 'Unknown',
    Requested = 'Requested',
    Positive = 'Positive',
    Negative = 'Negative',
}

/**
 * Citation for the response
 */
export interface Citation {
    id: string;
    link: string;
    sourceName: string;
    snippet: string;
}

export interface IChatMessage {
    id: string;
    roleId: string;
    type: ChatMessageType;
    authorRole: AuthorRoles;
    timestamp: string;
    content: string;
    prompt?: string;
    language?: string;
    citations?: Citation[];
    tokenUsage?: number;
    userFeedback?: UserFeedback;
}

export interface IChatMessageUpdate {
    id: string;
    content: string;
}

export interface IPartialContent {
    id: string;
    roleId: string;
    type: ChatMessageType;
    authorRole: AuthorRoles;
    timestamp: string;
    input: string;
    content: string;
}

export interface IPartialContentUpdate {
    id: string;
    input: string;
    content: string;
}
