import { t } from 'i18next';
import React, { useMemo } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeRaw from 'rehype-raw';
import 'katex/dist/katex.min.css';
import type {
    Citation,
    IChatMessage,
    ImageContent,
    TextContent,
} from '../../models/ChatMessage';

import { CodeBlock } from './CodeBlock';

interface DistinctCitation {
    prefix: string;
    link: string;
    sourceName: string;
}

interface ChatTextContentProps {
    message: IChatMessage;
}

export const ChatTextContent: React.FC<ChatTextContentProps> = ({
    message,
}) => {
    const parseContent = (
        contentString: string,
    ): (TextContent | ImageContent)[] | string => {
        try {
            if (contentString.startsWith('{"Items":')) {
                const parsed = JSON.parse(contentString);
                if (parsed && Array.isArray(parsed.Items)) {
                    return parsed.Items;
                }
            }
        } catch (error) {
            console.info('Failed to parse content:', error);
        }
        return contentString;
    };

    const content = parseContent(message.content);
    const citations = message.citations;

    const [contentWithCitations, distinctCitations] = useMemo(() => {
        if (typeof content === 'string') {
            if (message.authorRole === 'Bot') {
                let updatedContent = content;
                const usedCitationsMap: Map<string, DistinctCitation> =
                    new Map();
                const usedCitations: { index: number; citation: Citation }[] =
                    [];
                if (citations) {
                    const citationIdPattern = /\[([0-9a-fA-F]{32})\]/g;
                    const citationIdsSet = new Set(
                        content.match(citationIdPattern),
                    );
                    if (citationIdsSet.size > 0) {
                        const citationMap = new Map(
                            citations.map((citation) => [
                                citation.id,
                                citation,
                            ]),
                        );
                        citationIdsSet.forEach((citationIdMatch) => {
                            // Extract the GUID from the match, which includes the surrounding brackets
                            const citationId = citationIdMatch.slice(1, -1); // Remove the brackets
                            const citation = citationMap.get(citationId);

                            if (citation) {
                                const citationIndex = usedCitations.length + 1;
                                const snippet = citation.snippet
                                    .replace(/\r?\n|\r/g, ' ')
                                    .replace(/"/g, '\\"');
                                const citationLink = `[${citationIndex}](${citation.link} "${snippet}") `;
                                updatedContent = updatedContent.replace(
                                    new RegExp(`\\[${citationId}\\]`, 'g'),
                                    citationLink,
                                );

                                usedCitations.push({
                                    index: citationIndex,
                                    citation,
                                });

                                // Group citations by SourceName
                                const group = usedCitationsMap.get(
                                    citation.sourceName,
                                );
                                if (group) {
                                    group.prefix += `, ${citationIndex}`;
                                } else {
                                    usedCitationsMap.set(citation.sourceName, {
                                        prefix: `${citationIndex}`,
                                        link: citation.link,
                                        sourceName: citation.sourceName,
                                    });
                                }
                            }
                        });
                    }
                }
                const distinctCitations: DistinctCitation[] = Array.from(
                    usedCitationsMap.values(),
                );
                return [updatedContent, distinctCitations];
            }
            return [content, []];
        }
        return [content, []];
    }, [content, citations]);

    const renderContent = (
        content: (TextContent | ImageContent)[] | string,
    ) => {
        if (typeof content === 'string') {
            const processedText = content
                .replace(/\\\[/g, '$$') // Replace all occurrences of [ with $$
                .replace(/\\\]/g, '$$') // Replace all occurrences of ] with $$
                .replace(/\\\(/g, '$') // Replace all occurrences of ( with $$
                .replace(/\\\)/g, '$'); // Replace all occurrences of ) with $$

            const remarkMathOptions = {
                singleDollarTextMath: true,
            };
            return (
                <>
                    <Markdown
                        remarkPlugins={[
                            [remarkMath, remarkMathOptions],
                            remarkGfm,
                        ]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]}
                        components={{
                            code(props) {
                                return <CodeBlock {...props} />;
                            },
                            p(props) {
                                const { className, ...restProps } = props;
                                return (
                                    <p
                                        className={`break-words ${className || ''}`}
                                        {...restProps}
                                    />
                                );
                            },

                            span(props) {
                                const { className, ...restProps } = props;
                                return (
                                    <span
                                        /*add whitespace-pre-warp will make math format broken*/
                                        /* break-all also make math format broken*/
                                        className={`break-words ${className || ''}`}
                                        {...restProps}
                                    />
                                );
                            },
                            pre(props) {
                                const { className, ...restProps } = props;
                                return (
                                    <pre
                                        className={`break-words ${className || ''}`}
                                        {...restProps}
                                    />
                                );
                            },
                            a(props) {
                                const { className, ...restProps } = props;
                                return (
                                    <a
                                        style={{ verticalAlign: 'super' }}
                                        className={`link link-primary text-sm  ${className || ''}`}
                                        aria-label='link'
                                        {...restProps}
                                    >
                                        {restProps.children}
                                    </a>
                                );
                            },
                        }}
                    >
                        {processedText}
                    </Markdown>

                    {distinctCitations.length > 0 && (
                        <>
                            <h3 className='mt-2 font-bold'>
                                {t('chat.reference')}
                            </h3>
                            {distinctCitations.map((distinctCitation) => (
                                <div
                                    key={`citation-${distinctCitation.sourceName}`}
                                    className='flex flex-col mt-4'
                                >
                                    <Link
                                        to={distinctCitation.link} // 假设使用 react-router-dom 的 Link 组件
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='text-left no-underline mr-1'
                                    >
                                        <button className='btn-link overflow-hidden text-ellipsis block px-4 py-2 border focus:outline-none focus:ring-2 focus:ring-opacity-50'>
                                            {distinctCitation.prefix} |{' '}
                                            {distinctCitation.sourceName}
                                            <FiExternalLink className='inline ml-2' />
                                        </button>
                                    </Link>
                                </div>
                            ))}
                        </>
                    )}
                </>
            );
        }

        // 分离文本和图片内容
        const textContents = content.filter(
            (item): item is TextContent => item.$type === 'TextContent',
        );
        const imageContents = content.filter(
            (item): item is ImageContent => item.$type === 'ImageContent',
        );

        return (
            <>
                {/* 渲染文本内容，每个文本占据一行 */}
                {textContents.map((textItem, index) => (
                    <div key={`text-${index}`} className='w-full'>
                        <span className='whitespace-pre-wrap break-words overflow-x-auto'>
                            {textItem.Text}
                        </span>
                    </div>
                ))}

                {/* 渲染图片内容，图片左右排列 */}
                <div className='flex flex-row flex-wrap'>
                    {imageContents.map((imageItem, index) => (
                        <img
                            key={`image-${index}`}
                            src={imageItem.Uri}
                            alt='Content'
                            className='transition-transform duration-300 transform hover:z-10 hover:scale-150 max-w-xs max-h-[144px] object-cover mr-2 mb-2'
                        />
                    ))}
                </div>
            </>
        );
    };

    return (
        <div className='flex flex-col flex-none mr-2 w-full'>
            {renderContent(contentWithCitations)}
        </div>
    );
};
