//AppState.ts

export interface AppState {
    isMaintenance: boolean;
    // currentRoleId: {
    //     [userId: string]: string;
    // };
}

export const initialState: AppState = {
    isMaintenance: false,
};
