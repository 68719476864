// src/hooks/useChat.ts
import { useMsal } from '@azure/msal-react';
import { HubConnectionState, type HubConnection } from '@microsoft/signalr';
import { useCallback, useEffect } from 'react';

import type { IInput } from '../models/ChatInput';
import type { IChatMessage, IChatMessageUpdate } from '../models/ChatMessage';
import { useAppDispatch } from '../redux/hooks';
import {
    messageApiSlice,
    useGetMessagesByIdQuery,
    useAnswerMessageMutation,
} from '../services/messageApi';
import {
    getOrCreateHubConnection,
    waitForStateChange,
} from '../services/signalRConnection';

export function useAnswer(roleId: string, messageId: string) {
    const { instance } = useMsal();
    const dispatch = useAppDispatch();
    const account = instance.getActiveAccount();
    const userId = account?.localAccountId ?? '';

    const [answerMessage, { isError: isSendMessageError }] =
        useAnswerMessageMutation();

    const {
        data: messages,
        isError: isGetMessagesByIdError,
        refetch,
    } = useGetMessagesByIdQuery(
        { messageId },
        {
            skip: !userId || !messageId || !roleId,
            refetchOnMountOrArgChange: true,
        },
    );

    const wrappedAnswerMessage = async (input: IInput) => {
        const connection = await initializeSignalR();
        if (connection && connection.state === HubConnectionState.Connected) {
            await answerMessage(input).unwrap();
        }
    };

    const registerSignalREvents = useCallback(
        async (hubConnection: HubConnection) => {
            if (hubConnection) {
                console.log('registerSignalREvents');
                hubConnection.off('ReceiveMessage');
                hubConnection.on(
                    'ReceiveMessage',
                    (newMessage: IChatMessage) => {
                        console.log(
                            `on ReceiveMessage, roleId: ${roleId}, userId:${userId}, messageId:${newMessage.id},`,
                        );
                        dispatch(
                            messageApiSlice.util.updateQueryData(
                                'getMessagesById',
                                { messageId },
                                (draft) => {
                                    const index = draft.findIndex(
                                        (message) =>
                                            message.id === newMessage.id,
                                    );
                                    if (index !== -1) {
                                        draft[index].content =
                                            newMessage.content;
                                        console.log(
                                            `receiveMessage:messageId:${newMessage.id},${newMessage.content},RoleId:${roleId}`,
                                        );
                                    } else {
                                        draft.push(newMessage);
                                        console.log(
                                            `receiveMessage:draft push,messageId:${newMessage.id},${newMessage.content},RoleId:${roleId}`,
                                        );
                                    }
                                },
                            ),
                        );
                    },
                );
                hubConnection.off('ReceiveMessageUpdate');
                hubConnection.on(
                    'ReceiveMessageUpdate',
                    (updatedMessage: IChatMessageUpdate) => {
                        dispatch(
                            messageApiSlice.util.updateQueryData(
                                'getMessagesById',
                                {
                                    messageId,
                                },
                                (draft) => {
                                    const index = draft.findIndex(
                                        (message) =>
                                            message.id === updatedMessage.id,
                                    );
                                    if (index !== -1) {
                                        draft[index].content +=
                                            updatedMessage.content;
                                    } else {
                                        console.log(
                                            `ReceiveMessageUpdate failed:${updatedMessage.id}`,
                                        );
                                    }
                                    //test code.used to test long message history
                                    //draft.push(updatedMessage);
                                },
                            ),
                        );
                    },
                );
                hubConnection.off('ReceivePartialContent');
                hubConnection.on(
                    'ReceivePartialContent',
                    (newMessage: IChatMessage) => {
                        console.log(
                            `on ReceiveMessage, roleId: ${roleId}, userId:${userId}, messageId:${newMessage.id},`,
                        );
                        dispatch(
                            messageApiSlice.util.updateQueryData(
                                'getMessagesById',
                                { messageId },
                                (draft) => {
                                    const index = draft.findIndex(
                                        (message) =>
                                            message.id === newMessage.id,
                                    );
                                    if (index !== -1) {
                                        draft[index].content =
                                            newMessage.content;
                                        console.log(
                                            `receiveMessage:messageId:${newMessage.id},${newMessage.content},RoleId:${roleId}`,
                                        );
                                    } else {
                                        draft.push(newMessage);
                                        console.log(
                                            `receiveMessage:draft push,messageId:${newMessage.id},${newMessage.content},RoleId:${roleId}`,
                                        );
                                    }
                                },
                            ),
                        );
                    },
                );
                hubConnection.off('ReceivPartialContentUpdate');
                hubConnection.on(
                    'ReceivPartialContentUpdate',
                    (updatedMessage: IChatMessageUpdate) => {
                        dispatch(
                            messageApiSlice.util.updateQueryData(
                                'getMessagesById',
                                {
                                    messageId,
                                },
                                (draft) => {
                                    const index = draft.findIndex(
                                        (message) =>
                                            message.id === updatedMessage.id,
                                    );
                                    if (index !== -1) {
                                        draft[index].content +=
                                            updatedMessage.content;
                                    } else {
                                        console.log(
                                            `ReceiveMessageUpdate failed:${updatedMessage.id}`,
                                        );
                                    }
                                    //test code.used to test long message history
                                    //draft.push(updatedMessage);
                                },
                            ),
                        );
                    },
                );
            }
        },
        [dispatch, roleId, messageId, userId],
    );

    const initializeSignalR = async () => {
        let connection: HubConnection | null = null;
        for (let i = 0; i < 3; i++) {
            connection = await getOrCreateHubConnection();
            if (connection!.state === HubConnectionState.Connected) {
                break;
            }
            await waitForStateChange();
        }
        if (connection) {
            const sessionId = userId + '-' + roleId;
            console.log('invoke AddClientToGroupAsync');
            await connection.invoke('AddClientToGroupAsync', sessionId);
            await registerSignalREvents(connection);
            connection.off('onreconnected');
            connection.onreconnected(async (connectionId = '') => {
                if (connection?.state === HubConnectionState.Connected) {
                    const message =
                        'Connection reestablished. Please refresh the page to ensure you have the latest data.';
                    console.log(
                        message +
                            ` Connected with connectionId ${connectionId}`,
                    );
                    await connection.invoke(
                        'AddClientToGroupAsync',
                        userId + '-' + roleId,
                    );
                    await registerSignalREvents(connection);
                }
            });
        }
        return connection;
    };

    useEffect(() => {
        if (roleId && userId) {
            //let connection: HubConnection | null = null;
            const initialize = async () => {
                try {
                    await initializeSignalR();
                } catch (error) {
                    console.error(
                        'Error initializing SignalR connection:',
                        error,
                    );
                }
            };
            console.info('call initialize in useEffect');
            initialize();

            return () => {
                //if (connection) {
                //console.info('stop signalr connection');
                //connection.stop();
                //}
            };
        }
    }, [roleId, userId, messageId]);

    return {
        messages,
        isSendMessageError,
        isGetMessagesByIdError,
        sendMessage: wrappedAnswerMessage,
        refetch,
    };
}
