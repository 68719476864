import React from 'react';

export const NoPage = () => {
    return (
        <div className='flex flex-col flex-1'>
            <p>404</p>
        </div>
    );
};

export default NoPage;
