import type {
    SilentRequest,
    AuthenticationResult,
    EndSessionRequest,
} from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

import {
    AuthType,
    authType,
    loginRequest,
    method,
    msalInstance,
} from '../authConfig';

export const loginAsync = async () => {
    if (method === 'redirect') {
        await msalInstance.loginRedirect({
            scopes: loginRequest.scopes,
            prompt: 'select_account',
        });
    } else {
        await msalInstance.loginPopup({
            scopes: loginRequest.scopes,
            prompt: 'select_account',
        });
    }
};

export const logoutAsync = () => {
    const logoutRequest: EndSessionRequest = {
        postLogoutRedirectUri: window.origin,
        account: msalInstance.getActiveAccount() ?? undefined,
    };
    if (method === 'popup') {
        void msalInstance.logoutPopup(logoutRequest);
    } else {
        void msalInstance.logoutRedirect(logoutRequest);
    }
};

const isAuthAAD = () => authType === AuthType.AAD;

export const getAccessToken = async (): Promise<string | null> => {
    let accessToken: string | null = null;
    if (isAuthAAD()) {
        const activeAccount = msalInstance.getActiveAccount();

        if (activeAccount) {
            const accessTokenRequest: SilentRequest = {
                scopes: loginRequest.scopes,
                account: activeAccount,
            };

            try {
                const accessTokenResponse: AuthenticationResult =
                    await msalInstance.acquireTokenSilent(accessTokenRequest);
                accessToken = accessTokenResponse.accessToken;
            } catch (error) {
                // Check if the error is related to token expiration
                if (error instanceof InteractionRequiredAuthError) {
                    try {
                        // Initiate a new sign-in request
                        const accessTokenResponse: AuthenticationResult =
                            await msalInstance.acquireTokenPopup(
                                accessTokenRequest,
                            );
                        accessToken = accessTokenResponse.accessToken;
                    } catch (signInError) {
                        msalInstance.setActiveAccount(null);
                        console.error(signInError);
                    }
                } else {
                    console.warn(error);
                }
            }
        } else {
            console.info('no active account');
        }
    }
    return accessToken;
};

const authService = {
    getAccessToken,
    loginAsync,
    logoutAsync,
};

export default authService;
