//index.tsx
import { useMsal } from '@azure/msal-react';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaPlusMinus } from 'react-icons/fa6';
import { MdOutlinePersonRemove, MdEdit } from 'react-icons/md';

import { AuthType, authType } from '../../authConfig';
import MainWindow from '../../components/MainWindow';
import RoleEditor from '../../components/role/RoleEditor';
import Selector from '../../components/role/Selector';
import Sidebar from '../../components/sidebar';
import type { Item, MenuItem } from '../../components/sidebar/SidebarItem';
import SidebarItems from '../../components/sidebar/SidebarItems';
import { setCurrentRoleId } from '../../redux/features/role/roleReducer';
import type { RoleState } from '../../redux/features/role/RoleState';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
    useDeleteSessionsMutation,
    useGetSessionsQuery,
} from '../../services/sessionApi';

export const Chat = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const userId = account?.localAccountId ?? '';
    const dispatch = useAppDispatch();
    const currentRoleId = useAppSelector(
        (state: { role: RoleState }) => state.role?.[userId]?.currentRoleId,
    );

    //const [currentItemId, setCurrentItemId] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [items, setItems] = useState<Item[]>([]);
    const [openSelector, setOpenSelector] = useState<boolean>(false);
    const [openRoleEditor, setOpenRoleEditor] = useState<boolean>(false);
    const [selectedRoleId, setSelectedRoleId] = useState<string>('');
    const { data, error, isLoading } = useGetSessionsQuery();
    const [deleteSessions] = useDeleteSessionsMutation();
    useEffect(() => {
        if (data) {
            const convertedItems = data.map((item) => {
                // 创建一个基础的菜单项数组，始终包含 'Remove' 菜单项
                const menuItems: MenuItem[] = [
                    {
                        id: 'Remove',
                        name: 'global.remove',
                        icon: MdOutlinePersonRemove,
                        onClick: () => {
                            handleRemove(item.roleId);
                        },
                    },
                ];

                // 如果 item.readonly 不为 true，则添加 'Edit' 菜单项
                if (!item.readonly) {
                    menuItems.push({
                        id: 'Edit',
                        name: 'global.edit',
                        icon: MdEdit,
                        onClick: () => {
                            handleEdit(item.roleId);
                        },
                    });
                }

                // 返回转换后的对象
                return {
                    id: item.id,
                    roleId: item.roleId,
                    title: item.title,
                    icon: item.icon,
                    menuItems: menuItems,
                    onClick: () => {
                        dispatch(
                            setCurrentRoleId({ userId, roleId: item.roleId }),
                        );
                    },
                };
            });
            setItems(convertedItems);
            if (!currentRoleId && convertedItems.length > 0) {
                dispatch(
                    setCurrentRoleId({
                        userId,
                        roleId: convertedItems[0].roleId,
                    }),
                );
            }
        }
    }, [data]);

    const handleRemove = async (roleId: string) => {
        await deleteSessions([roleId]);
    };

    const handleEdit = (roleId: string) => {
        setSelectedRoleId(roleId);
        setOpenRoleEditor(true);
    };

    if (authType === AuthType.AAD && !account) {
        return (
            <main className='flex-1 w-full flex flex-col items-center justify-center'>
                <p className='text-xl'>{t('chat.signInRequired')}</p>
            </main>
        );
    }

    if (isLoading)
        return (
            <main className='flex-1 w-full flex flex-col items-center justify-center'>
                <AiOutlineLoading3Quarters className='animate-spin h-12 w-12' />
            </main>
        );

    if (error)
        return (
            <main className='flex-1 w-full flex flex-col items-center justify-center'>
                <p className='text-xl'>{(error as { data: string }).data}</p>
            </main>
        );

    return (
        <main className='w-full flex flex-row flex-1 overflow-auto bg-base-100 text-base-content'>
            {!openSelector && !openRoleEditor && (
                <div className='flex flex-row flex-1 overflow-auto'>
                    {data && (
                        <Sidebar
                            isOpen={isOpen}
                            setIsOpen={() => setIsOpen((prev) => !prev)}
                        >
                            <SidebarItems
                                items={items}
                                setItems={setItems}
                                currentItemId={currentRoleId}
                                isOpen={isOpen}
                                draggable={true}
                            />
                            <div className='btn-ghost flex justify-start my-2 text-primary/80'>
                                <button
                                    className=' flex items-center whitespace-nowrap text-left pl-2 min-w-14 '
                                    onClick={() => setOpenSelector(true)}
                                >
                                    <FaPlusMinus className='mx-2 flex-shrink-0' />
                                    <p
                                        className={`ml-2 flex flex-shrink-1 flex-grow flex-nowrap justify-start transition-opacity duration-50  ${
                                            isOpen
                                                ? 'opacity-100 delay-300'
                                                : 'opacity-0'
                                        }`}
                                    >
                                        {t('chat.addminus')}
                                    </p>
                                </button>
                            </div>
                        </Sidebar>
                    )}
                    <MainWindow currentRoleId={currentRoleId} />
                </div>
            )}
            {openSelector && (
                <Selector
                    currentRoleId={currentRoleId}
                    onClose={() => setOpenSelector(false)}
                />
            )}
            {openRoleEditor && (
                <RoleEditor
                    roleId={selectedRoleId}
                    onClose={() => setOpenRoleEditor(false)}
                />
            )}
        </main>
    );
};
