//appSlice.ts
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';

import { initialRoleState } from './RoleState';
import type { PageData } from './RoleState';

// 辅助函数，确保页面状态存在
const ensurePageState = (state: any, userId: string, roleId: string) => {
    if (!state[userId]) {
        state[userId] = {
            currentRoleId: roleId,
            data: {},
        };
    }
    if (!state[userId].data[roleId]) {
        state[userId].data[roleId] = {
            pages: [],
            currentPageIndex: 0,
        };
    }
};

// 辅助函数，更新页面参数
const updatePage = (
    state: any,
    userId: string,
    roleId: string,
    pageIndex: number,
    updateFn: (page: PageData) => void,
) => {
    ensurePageState(state, userId, roleId);
    const pages = state[userId].data[roleId].pages;
    let page = pages[pageIndex];

    if (!page) {
        page = {
            id: uuidv1().replace(/-/g, ''),
            input: '',
            output: '',
            params: {},
        };
        pages[pageIndex] = page;
    }

    updateFn(page);
};

export const roleSlice = createSlice({
    name: 'role',
    initialState: {
        ...initialRoleState,
    },
    reducers: {
        addPage: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageData: PageData;
            }>,
        ) => {
            const { userId, roleId, pageData } = action.payload;

            ensurePageState(state, userId, roleId);
            state[userId].data[roleId].pages.push(pageData);
            state[userId].data[roleId].currentPageIndex =
                state[userId].data[roleId].pages.length - 1;
        },
        // 添加页面图片
        addPageImage: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
                image: string;
            }>,
        ) => {
            const { userId, roleId, pageIndex, image } = action.payload;
            updatePage(state, userId, roleId, pageIndex, (page) => {
                const images = (page.params.images as string[]) ?? [];
                images.push(image);
                page.params.images = images;
            });
        },
        updateCurrentPage: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageData: PageData;
            }>,
        ) => {
            const { userId, roleId, pageData } = action.payload;
            ensurePageState(state, userId, roleId);
            const currentIndex =
                state[userId].data[roleId].currentPageIndex ?? 0;
            if (!state[userId].data[roleId].pages[currentIndex]) {
                state[userId].data[roleId].pages.push(pageData);
            } else {
                state[userId].data[roleId].pages[currentIndex] = pageData;
            }
        },
        // 更新页面的语言
        updatePageLanguage: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
                language: string;
            }>,
        ) => {
            const { userId, roleId, pageIndex, language } = action.payload;
            updatePage(state, userId, roleId, pageIndex, (page) => {
                page.params.language = language;
            });
        },
        updatePageSize: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
                size: number;
            }>,
        ) => {
            const { userId, roleId, pageIndex, size } = action.payload;
            updatePage(state, userId, roleId, pageIndex, (page) => {
                page.params.size = size;
            });
        },
        // 更新页面的输入
        updatePageInput: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
                input: string;
            }>,
        ) => {
            const { userId, roleId, pageIndex, input } = action.payload;
            updatePage(state, userId, roleId, pageIndex, (page) => {
                page.input = input;
            });
        },

        updatePageKeywords: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
                keywords: string;
            }>,
        ) => {
            const { userId, roleId, pageIndex, keywords } = action.payload;
            updatePage(state, userId, roleId, pageIndex, (page) => {
                page.params.keywords = keywords;
            });
        },
        // 删除页面图片
        deletePageImage: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
                image: string;
            }>,
        ) => {
            const { userId, roleId, pageIndex, image } = action.payload;
            updatePage(state, userId, roleId, pageIndex, (page) => {
                const images = (page.params.images as string[]) ?? [];
                if (images) {
                    const newImages = images.filter((img) => img !== image);
                    page.params.images = newImages;
                }
            });
        },

        // 清除页面的所有图片
        clearPageImages: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
            }>,
        ) => {
            const { userId, roleId, pageIndex } = action.payload;
            updatePage(state, userId, roleId, pageIndex, (page) => {
                page.params.images = [];
            });
        },

        // 更新页面的输出
        updatePageOutput: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
                output: string;
            }>,
        ) => {
            const { userId, roleId, pageIndex, output } = action.payload;
            updatePage(state, userId, roleId, pageIndex, (page) => {
                page.output = output;
            });
        },

        // 删除页面
        deletePage: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
            }>,
        ) => {
            const { userId, roleId, pageIndex } = action.payload;

            ensurePageState(state, userId, roleId);
            const pages = state[userId].data[roleId].pages;
            if (pageIndex >= 0 && pageIndex < pages.length) {
                // 使用filter方法创建不包含被删除页面的新数组
                const newPages = pages.filter(
                    (_, index) => index !== pageIndex,
                );
                state[userId].data[roleId].pages = newPages;
                // 更新currentPageIndex
                if (
                    state[userId].data[roleId].currentPageIndex >=
                    newPages.length
                ) {
                    state[userId].data[roleId].currentPageIndex =
                        newPages.length - 1;
                }
            }
        },

        // 设置当前页面索引
        setCurrentPageIndex: (
            state,
            action: PayloadAction<{
                userId: string;
                roleId: string;
                pageIndex: number;
            }>,
        ) => {
            const { userId, roleId, pageIndex } = action.payload;
            ensurePageState(state, userId, roleId);
            state[userId].data[roleId].currentPageIndex = pageIndex;
        },

        setCurrentRoleId: (
            state,
            action: PayloadAction<{ userId: string; roleId: string }>,
        ) => {
            const { userId, roleId } = action.payload;
            if (!state[userId]) {
                state[userId] = {
                    currentRoleId: roleId,
                    data: {},
                };
            } else {
                state[userId].currentRoleId = roleId;
            }
        },
    },
});

export const {
    addPage,
    updateCurrentPage,
    setCurrentPageIndex,
    updatePageInput,
    updatePageOutput,
    updatePageSize,
    updatePageLanguage,
    updatePageKeywords,
    deletePage,
    addPageImage,
    deletePageImage,
    clearPageImages,
    setCurrentRoleId,
} = roleSlice.actions;

export default roleSlice.reducer;
