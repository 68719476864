// src/components/chat/ChatWindow.tsx
import { useMsal } from '@azure/msal-react';
import { t } from 'i18next';
import React, { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdOutlineRestartAlt } from 'react-icons/md';

import { useClearMessagesMutation } from '../services/messageApi';
import { useGetRoleByIdQuery } from '../services/sessionApi';

import ChatRoom from './chat/ChatRoom';
import FileUploader from './content/FileUploader';
import ContentGeneration from './ContentGeneration';
import ContentTransform from './ContentTransform';

interface MainWindowProps {
    currentRoleId: string;
}
const MainWindow: React.FC<MainWindowProps> = ({ currentRoleId }) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const userId = account?.localAccountId ?? '';
    const { data: role } = useGetRoleByIdQuery(currentRoleId);
    const [activeTab, setActiveTab] = useState<'chat' | 'upload'>('chat');
    const [clearMessages] = useClearMessagesMutation();

    const clearChatHistory = async () => {
        try {
            // 调用mutation函数来清除消息
            await clearMessages({ roleId: currentRoleId, userId });
        } catch (error) {
            // 处理可能的错误
            console.error('Failed to clear messages:', error);
        }
    };
    if (!role) {
        return (
            <div className='flex-1 w-full flex flex-col items-center justify-center'>
                <AiOutlineLoading3Quarters className='animate-spin h-12 w-12' />
            </div>
        );
    }
    if (role?.category === 'Translation') {
        return (
            <div className='p-4 flex flex-1 flex-col'>
                <ContentTransform
                    name='translation'
                    roleId={role.id}
                    features={{ showLanguage: true }}
                />
            </div>
        );
    } else if (role?.category === 'Summary') {
        return (
            <div className='p-4 flex flex-1 flex-col'>
                <ContentTransform
                    name='summary'
                    roleId={role.id}
                    features={{ showSize: true }}
                />
            </div>
        );
    } else if (role?.category === 'Polish') {
        return (
            <div className='p-4 flex flex-1 flex-col'>
                <ContentTransform name='polish' roleId={role.id} />
            </div>
        );
    } else if (role?.category === 'Author') {
        return (
            <div className='p-4 flex flex-1 flex-col h-full w-full'>
                <ContentGeneration
                    roleId={role.id}
                    features={{
                        showKeywords: true,
                        enableContent: true,
                        enableOutline: true,
                    }}
                />
            </div>
        );
    } else {
        return (
            // MainWindow component here
            <div className='p-4 flex flex-1 flex-col bg-base-100 text-base-content'>
                <div className='flex flex-row flex-none justify-between '>
                    <div className='flex flex-row flex-none '>
                        <button
                            className={`mx-2 pb-2 ${
                                activeTab === 'chat'
                                    ? 'border-b-2 border-info '
                                    : ''
                            }`}
                            onClick={() => setActiveTab('chat')}
                        >
                            {t('chat.chat')}
                        </button>
                        {role?.useKB !== false && (
                            <button
                                className={`mx-2 pb-2 ${
                                    activeTab === 'upload'
                                        ? 'border-b-2 border-info'
                                        : ''
                                }`}
                                onClick={() => setActiveTab('upload')}
                            >
                                {t('chat.files')}
                            </button>
                        )}
                    </div>
                    {activeTab === 'chat' && (
                        <button
                            className='btn-ghost flex items-center justify-center cursor-pointer mr-2 px-2 '
                            onClick={clearChatHistory}
                            title={t('chat.clear')}
                            aria-label={t('chat.clear')}
                        >
                            <MdOutlineRestartAlt className=' w-5 h-5' />
                        </button>
                    )}
                </div>
                <div className='flex-auto flex flex-col overflow-auto mt-2 '>
                    {activeTab === 'chat' && (
                        <ChatRoom roleId={currentRoleId} />
                    )}
                    {role?.useKB !== false && activeTab === 'upload' && (
                        <FileUploader roleId={currentRoleId} type='personal' />
                    )}
                </div>
            </div>
        );
    }
};

export default MainWindow;
