// src/middleware/sessionStorageMiddleware.ts
import type { Action, Dispatch, Middleware } from '@reduxjs/toolkit';

import type { RootState } from '../store';

// 定义中间件的类型
type SessionStorageMiddleware = Middleware<{}, RootState, Dispatch<Action>>;

export const sessionStorageMiddleware: SessionStorageMiddleware =
    (store) => (next) => (action) => {
        const result = next(action);
        // 保存状态到 sessionStorage
        sessionStorage.setItem('state', JSON.stringify(store.getState()));
        return result;
    };

export const loadStateFromSessionStorage = (): RootState | undefined => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState) as RootState;
    } catch (err) {
        return undefined;
    }
};
