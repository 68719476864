// SidebarItems.tsx
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DraggableSidebarItem, SidebarItem } from './SidebarItem';
import type { Item } from './SidebarItem';

interface SidebarItemsProps {
    items: Item[];
    setItems?: (items: Item[]) => void;
    currentItemId: string;
    isOpen: boolean;
    draggable?: boolean;
}

export const SidebarItems: React.FC<SidebarItemsProps> = ({
    items,
    setItems,
    currentItemId,
    isOpen,
    draggable = false,
}) => {
    const processedItems =
        items.length === 1
            ? items.map((item) => ({
                  ...item,
                  menuItems: item.menuItems.filter(
                      (menuItem) => menuItem.id !== 'Remove',
                  ),
              }))
            : items;

    const moveItem = (dragIndex: number, hoverIndex: number) => {
        const dragItem = items[dragIndex];
        const newItems = [...items];
        newItems.splice(dragIndex, 1);
        newItems.splice(hoverIndex, 0, dragItem);
        setItems?.(newItems);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className='flex flex-col flex-none text-left'>
                {processedItems?.map((item, index) =>
                    draggable ? (
                        <DraggableSidebarItem
                            key={item.id}
                            isOpen={isOpen}
                            isSelected={currentItemId === item.roleId}
                            index={index}
                            moveItem={moveItem}
                            item={item}
                        />
                    ) : (
                        <SidebarItem
                            key={item.id}
                            isOpen={isOpen}
                            isSelected={currentItemId === item.roleId}
                            item={item}
                        />
                    ),
                )}
            </div>
        </DndProvider>
    );
};

export default SidebarItems;
