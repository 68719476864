// src/components/role/CustomRangeSlider.tsx
import React, { useState, useCallback, useRef } from 'react';

interface RangeSliderProps {
    name: string;
    min: number;
    max: number;
    step: number;
    value: number;
    onChange: (name: string, newValue: number) => void;
    className?: string;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
    name,
    min,
    max,
    step,
    value,
    onChange,
    className,
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const sliderRef = useRef<HTMLDivElement>(null);
    const thumbRef = useRef<HTMLDivElement>(null);

    const formatValue = (value: number) => {
        const stepDecimalPlaces = (step.toString().split('.')[1] || '').length;
        return value.toFixed(stepDecimalPlaces);
    };

    const roundValueToStep = (value: number) => {
        const roundedValue = Math.round((value - min) / step) * step + min;
        const rangedValue = Math.min(Math.max(roundedValue, min), max);
        const stepDecimalPlaces = (step.toString().split('.')[1] || '').length;
        return parseFloat(rangedValue.toFixed(stepDecimalPlaces));
    };

    const handleDrag = useCallback(
        (clientX: number) => {
            if (sliderRef.current) {
                const rect = sliderRef.current.getBoundingClientRect();
                const newValue =
                    ((clientX - rect.left) / rect.width) * (max - min) + min;
                onChange(name, roundValueToStep(newValue));
            }
        },
        [min, max, step, onChange],
    );

    const handleMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            setIsDragging(true);
            setShowTooltip(true);
            handleDrag(e.clientX);
        },
        [handleDrag],
    );

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (isDragging) {
                handleDrag(e.clientX);
            }
        },
        [isDragging, handleDrag],
    );

    const handleMouseUp = useCallback(
        (e: MouseEvent) => {
            setIsDragging(false);
            const thumb = thumbRef.current;
            if (thumb) {
                const thumbRect = thumb.getBoundingClientRect();
                // 检查鼠标位置是否在滑块上
                const isMouseOnThumb =
                    e.clientX >= thumbRect.left &&
                    e.clientX <= thumbRect.right &&
                    e.clientY >= thumbRect.top &&
                    e.clientY <= thumbRect.bottom;
                if (!isMouseOnThumb) {
                    setShowTooltip(false);
                }
            } else {
                setShowTooltip(false);
            }
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        },
        [handleMouseMove],
    );

    const handleMouseEnter = useCallback(() => {
        setShowTooltip(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (!isDragging) {
            setShowTooltip(false);
        }
    }, [isDragging]);

    // Attach mousemove and mouseup event listeners to the document
    React.useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    const calculateThumbPosition = (value: number) => {
        const percentage = ((value - min) / (max - min)) * 100;
        return `calc(${percentage}% - (8px))`;
    };
    const calculateTooltipPosition = (value: number) => {
        const percentage = ((value - min) / (max - min)) * 100;
        return `calc(${percentage}%)`;
    };

    return (
        <div
            ref={sliderRef}
            className={`${className} relative w-full h-2 bg-base-200 rounded-lg cursor-pointer`}
            role='slider'
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            tabIndex={0}
            onMouseDown={handleMouseDown}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={thumbRef}
                className='absolute top-1/2 transform -translate-y-1/2 w-4 h-4 bg-primary rounded-full'
                style={{ left: calculateThumbPosition(value) }}
            />
            {showTooltip && (
                <div
                    className='absolute text-xs bg-primary text-primary-content py-1 px-2 rounded'
                    style={{
                        left: calculateTooltipPosition(value),
                        top: '-36px',
                        transform: 'translateX(-50%)',
                        pointerEvents: 'none',
                    }}
                >
                    {formatValue(value)}
                    <svg
                        className='absolute text-primary h-3 w-full fill-current'
                        style={{
                            top: '100%',
                            left: '50%',
                            transform: 'translateX(-50%) translateY(-1px)',
                        }}
                        viewBox='0 0 255 255'
                        xmlSpace='preserve'
                    >
                        <polygon points='0,0 127.5,127.5 255,0'></polygon>
                    </svg>
                </div>
            )}
        </div>
    );
};

export default RangeSlider;
