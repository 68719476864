// src/components/role/index.tsx
import { Switch } from '@headlessui/react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdClose, MdAdd, MdRemove } from 'react-icons/md';
import { v1 as uuidv1 } from 'uuid';

import type { IRole, ConversationSample } from '../../models/Role';
import {
    useCreateRoleMutation,
    useGetRoleByIdQuery,
    useUpdateRoleMutation,
} from '../../services/sessionApi';
import FileUploader from '../content/FileUploader';

import { ImagePicker } from './ImagePicker';
import RangeSlider from './RangeSlider';

const defaultRole: IRole = {
    id: uuidv1().replace(/-/g, ''),
    category: 'Chat',
    title: 'Default Role',
    icon: '',
    systemMsg: '',
    temperature: 0,
    model: 'gpt_4o',
    top_p: 1,
    presence_penalty: 0,
    frequency_penalty: 0,
    max_tokens: 0,
    maxHistory: 10,
    samples: [],
    readonly: false,
    voice: 'zh-CN-XiaoxiaoNeural',
    useKB: null,
    kbOnly: false,
    useWeb: null,
    shareLevel: 'Private',
};

interface RoleEditorProps {
    roleId?: string | undefined;
    onClose: () => void;
}

const RoleEditor: React.FC<RoleEditorProps> = ({ roleId, onClose }) => {
    const { data: role, isFetching } = useGetRoleByIdQuery(roleId ?? skipToken);
    const [roleState, setRoleState] = useState<IRole>(defaultRole);
    const [createRole] = useCreateRoleMutation();
    const [updateRole] = useUpdateRoleMutation();
    const [openKB, setOpenKB] = useState(false);
    useEffect(() => {
        if (role) {
            setRoleState(role);
        }
    }, [role]);

    if (isFetching)
        return (
            <main className='flex-1 w-full flex flex-col items-center justify-center'>
                <AiOutlineLoading3Quarters className='animate-spin h-12 w-12' />
            </main>
        );

    const handleClose = () => {
        onClose();
    };

    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >,
    ) => {
        const { name, value } = e.target;
        setRoleState({ ...roleState, [name]: value });
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        const boolValue: boolean | null =
            value === 'null' ? null : value === 'true';
        setRoleState({ ...roleState, [name]: boolValue });
    };

    const handleRangeSliderChange = (name: string, value: number) => {
        setRoleState({ ...roleState, [name]: value });
    };

    const handleIconChange = (icon: string) => {
        setRoleState({ ...roleState, icon });
    };

    const handleSampleChange = (
        index: number,
        field: keyof ConversationSample,
        value: string,
    ) => {
        const newSamples = [...roleState.samples];
        newSamples[index] = { ...newSamples[index], [field]: value };
        setRoleState({ ...roleState, samples: newSamples });
    };

    const handleAddSample = () => {
        const newSamples = [...roleState.samples, { question: '', answer: '' }];
        setRoleState({ ...roleState, samples: newSamples });
    };

    const handleRemoveSample = (index: number) => {
        const newSamples = [...roleState.samples];
        newSamples.splice(index, 1);
        setRoleState({ ...roleState, samples: newSamples });
    };

    const handleSave = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (role) {
            await updateRole(roleState);
        } else {
            roleState.id = uuidv1().replace(/-/g, '');
            if (!roleState.icon) {
                roleState.icon = '/icons/default.png';
            }
            if (roleState.category !== 'Chat') {
                roleState.useKB = false;
                roleState.kbOnly = false;
                roleState.useWeb = false;
                roleState.maxHistory = 0;
                roleState.max_tokens = 0;
            }
            await createRole(roleState);
        }
        handleClose();
    };

    return (
        <>
            {openKB &&
                roleState.useKB !== false &&
                roleState.shareLevel === 'All' && (
                    <div className='bg-base-100 text-base-content flex flex-auto flex-col overflow-auto mt-4'>
                        <FileUploader
                            roleId={roleId!}
                            type='group'
                            onClose={() => setOpenKB(false)}
                        />
                    </div>
                )}
            {!openKB && (
                <div className='flex flex-1 flex-col space-y-2 p-2'>
                    <div className='flex flex-row items-center space-x-4 justify-between px-2'>
                        <p className='label text-lg p-2'>
                            {roleId ? t('role.editRole') : t('role.newRole')}
                        </p>
                        <div className='flex flex-row justify-end p-2'>
                            <div className='flex flex-row justify-end'>
                                {roleState.useKB !== false &&
                                    roleState.shareLevel === 'All' && (
                                        <button
                                            onClick={() => setOpenKB(true)}
                                            className={`btn btn-outline btn-primary mr-2  inline-flex items-center justify-center px-4  
                            }`}
                                        >
                                            {t('role.kb')}
                                        </button>
                                    )}

                                <button
                                    onClick={handleSave}
                                    className={`btn btn-outline btn-primary mr-2 inline-flex items-center justify-center px-4`}
                                >
                                    {t('global.save')}
                                </button>
                            </div>
                            <button
                                onClick={handleClose}
                                className='btn btn-primary btn-ghost inline-flex justify-center px-2 py-0 rounded-full'
                                aria-label='Close'
                            >
                                <MdClose className='w-6 h-6 text-primary' />
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-row items-center space-x-4 justify-between px-4'>
                        <label
                            htmlFor='category'
                            className='label flex flex-none'
                        >
                            {t('role.category')}
                        </label>
                        <select
                            name='category'
                            id='category'
                            value={roleState.category ?? 'Chat'}
                            onChange={handleChange}
                            className='select select-bordered w-full max-w-xs mt-1 flex-1 text-sm'
                        >
                            <option value='Chat'>{t('role.chat')}</option>
                            <option value='Summarize'>
                                {t('role.summary')}
                            </option>
                            <option value='Translation'>
                                {t('role.translation')}
                            </option>
                            <option value='Polish'>{t('role.polish')}</option>
                            <option value='Author'>{t('role.writing')}</option>
                            {/*<option value='Agent'>{t('role.agent')}</option>*/}
                        </select>
                    </div>
                    <div className='flex flex-row items-center space-x-4 justify-between px-4'>
                        <label
                            htmlFor='title'
                            className='label flex flex-none text-md'
                        >
                            {t('role.title')}
                        </label>
                        <input
                            type='text'
                            name='title'
                            id='title'
                            value={roleState.title ?? ''}
                            onChange={handleChange}
                            className='input input-bordered text-sm mt-1 flex-1 w-full max-w-xs'
                        />
                    </div>
                    <div className='flex flex-row items-center space-x-4 justify-between px-4'>
                        <label htmlFor='icon' className='label flex flex-none'>
                            {t('role.icon')}
                        </label>
                        <ImagePicker
                            name='icon'
                            imageData={roleState.icon}
                            onAccepted={handleIconChange}
                        />
                    </div>
                    <div className='flex flex-row items-center space-x-4 justify-between px-4'>
                        <label
                            htmlFor='systemMsg'
                            className='label flex flex-none'
                        >
                            {t('role.sysMessage')}
                        </label>
                        <textarea
                            name='systemMsg'
                            id='systemMsg'
                            value={roleState.systemMsg}
                            placeholder='Override the default system message...'
                            onChange={handleChange}
                            className='textarea textarea-bordered mt-1 flex-1 w-full h-20 min-h-20 sm:max-w-md xl:max-w-3xl '
                        />
                    </div>
                    <div className='flex flex-row items-center justify-between px-4'>
                        <label
                            htmlFor='model'
                            className='label flex flex-none '
                        >
                            {t('role.model')}
                        </label>
                        <select
                            name='model'
                            id='model'
                            value={roleState.model ?? 'gpt_4o'}
                            onChange={handleChange}
                            className='select select-bordered mt-1 flex-1 text-sm max-w-xs'
                        >
                            <option value='gpt_35_turbo_16k'>
                                GPT-3.5-16k
                            </option>
                            <option value='gpt_4o'>GPT-4o</option>
                            <option value='gpt_4_turbo'>GPT-4-Turbo</option>
                            {/*<option value='gpt_4_32k'>GPT-4-32k</option>*/}
                            {/*<option value='gpt_4_vision'>GPT-4-Vision</option>*/}
                        </select>
                    </div>
                    <div className='flex flex-row items-center justify-between px-4'>
                        <label
                            htmlFor='temperature'
                            className='label flex flex-none'
                        >
                            {t('role.temperature')}
                        </label>
                        <RangeSlider
                            name='temperature'
                            min={0}
                            max={1.0}
                            step={0.01}
                            value={roleState.temperature ?? 0.1}
                            onChange={handleRangeSliderChange}
                            className='mt-1 flex-1 w-full max-w-sm rounded-md'
                        />
                    </div>
                    <div className='flex flex-row items-center justify-between px-4'>
                        <label htmlFor='top_p' className='label flex flex-none'>
                            {t('role.topP')}
                        </label>
                        <RangeSlider
                            name='top_p'
                            min={0}
                            max={1.0}
                            step={0.01}
                            value={roleState.top_p ?? 1.0}
                            onChange={handleRangeSliderChange}
                            className='mt-1 flex-1 w-full max-w-sm rounded-md'
                        />
                    </div>
                    {/* <div className='flex items-center justify-between px-4'>
                        <label
                            htmlFor='presence_penalty'
                            className='label flex flex-none'
                        >
                            {t('role.presence')}
                        </label>
                        <RangeSlider
                            name='presence_penalty'
                            min={-1}
                            max={1.0}
                            step={0.01}
                            value={roleState.presence_penalty ?? 0}
                            onChange={handleRangeSliderChange}
                            className='mt-1 flex-1 w-full max-w-sm rounded-md'
                        />
                    </div>
                    <div className='flex items-center justify-between px-4'>
                        <label
                            htmlFor='frequency_penalty'
                            className='label flex flex-none'
                        >
                            {t('role.frequency')}
                        </label>
                        <RangeSlider
                            name='frequency_penalty'
                            min={-1}
                            max={1.0}
                            step={0.01}
                            value={roleState.frequency_penalty ?? 0}
                            onChange={handleRangeSliderChange}
                            className='mt-1 flex-1 w-full max-w-sm rounded-md'
                        />
                    </div> */}
                    {roleState.category === 'Chat' && (
                        <div className='flex flex-row items-center justify-between px-4'>
                            <label
                                htmlFor='max_tokens'
                                className='label flex flex-none'
                            >
                                {t('role.maxTokens')}
                            </label>
                            <input
                                type='number'
                                name='max_tokens'
                                id='max_tokens'
                                min={0}
                                value={roleState.max_tokens ?? 0}
                                onChange={handleChange}
                                className='input input-bordered mt-1 flex-1 w-full max-w-xs'
                            />
                        </div>
                    )}
                    {roleState.category === 'Chat' && (
                        <div className='flex flex-row items-center justify-between px-4'>
                            <label
                                htmlFor='maxHistory'
                                className='label flex flex-none'
                            >
                                {t('role.maxHistory')}
                            </label>
                            <input
                                type='number'
                                name='maxHistory'
                                id='maxHistory'
                                min={0}
                                value={roleState.maxHistory ?? 50}
                                onChange={handleChange}
                                className='input input-bordered mt-1 flex-1 w-full max-w-xs'
                            />
                        </div>
                    )}
                    {roleState.category === 'Chat' && (
                        <div className='flex flex-row items-center justify-between px-4'>
                            <label
                                htmlFor='useKB'
                                className='label flex flex-none'
                            >
                                {t('role.useKb')}
                            </label>
                            <select
                                name='useKB'
                                id='useKB'
                                value={
                                    roleState.useKB === null ||
                                    roleState.useKB === undefined
                                        ? 'null'
                                        : roleState.useKB.toString()
                                }
                                onChange={handleSelectChange}
                                className='select select-bordered w-full max-w-xs mt-1 flex-1 text-sm'
                            >
                                <option value='null'>{t('role.auto')}</option>
                                <option value='false'>{t('role.false')}</option>
                                <option value='true'>{t('role.true')}</option>
                            </select>
                        </div>
                    )}
                    {roleState.category === 'Chat' &&
                        roleState.useKB === true && (
                            <div className='flex flex-row items-center justify-between px-4'>
                                <label
                                    htmlFor='kbOnly'
                                    className='label flex flex-none'
                                >
                                    {t('role.kbOnly')}
                                </label>
                                <Switch
                                    checked={roleState.kbOnly}
                                    onChange={(value: boolean) =>
                                        setRoleState({
                                            ...roleState,
                                            kbOnly: value,
                                        })
                                    }
                                    className={`${
                                        roleState.kbOnly
                                            ? 'bg-primary'
                                            : 'bg-base-300'
                                    } relative items-center justify-start h-6 w-11 inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-base-100/75`}
                                >
                                    <span
                                        aria-hidden='true'
                                        className={`bg-base-100 ${
                                            roleState.kbOnly
                                                ? 'translate-x-6'
                                                : 'translate-x-0'
                                        } pointer-events-none h-4 w-4 inline-block transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                                    />
                                </Switch>
                            </div>
                        )}
                    {roleState.category === 'Chat' && (
                        <div className='flex flex-row items-center justify-between px-4'>
                            <label
                                htmlFor='useWeb'
                                className='label flex flex-none'
                            >
                                {t('role.useWeb')}
                            </label>
                            <select
                                name='useWeb'
                                id='useWeb'
                                value={
                                    roleState.useWeb === null ||
                                    roleState.useWeb === undefined
                                        ? 'null'
                                        : roleState.useWeb.toString()
                                }
                                onChange={handleSelectChange}
                                className='select select-bordered w-full max-w-xs mt-1 flex-1 text-sm'
                            >
                                <option value='null'>{t('role.auto')}</option>
                                <option value='false'>{t('role.false')}</option>
                                <option value='true'>{t('role.true')}</option>
                            </select>
                        </div>
                    )}
                    {/* <div className='flex flex-row items-center justify-between px-4'>
                        <label htmlFor='voice' className='label flex flex-none'>
                            {t('role.voice')}
                        </label>
                        <select
                            name='voice'
                            id='voice'
                            value={roleState.voice}
                            onChange={handleChange}
                            className='select select-bordered w-full max-w-xs mt-1 flex-1 text-sm'
                        >
                            <option value='zh-CN-XiaohanNeural'>
                                {t('role.xiaohan')}
                            </option>
                            <option value='zh-CN-XiaoxiaoNeural'>
                                {t('role.xiaoxiao')}
                            </option>
                            <option value='zh-CN-YunjianNeural'>
                                {t('role.yunjian')}
                            </option>
                            <option value='zh-CN-YunxiNeural'>
                                {t('role.yunxi')}
                            </option>
                            <option value='zh-CN-XiaoqiuNeural'>
                                {t('role.xiaoqiu')}
                            </option>
                            <option value='zh-CN-YunyeNeural'>
                                {t('role.yunye')}
                            </option>
                            <option value='zh-CN-XiaoshuangNeural'>
                                {t('role.xiaoshuang')}
                            </option>
                            <option value='zh-CN-sichuan-YunxiNeural'>
                                {t('role.sichuanyunxi')}
                            </option>
                            <option value='zh-CN-liaoning-XiaobeiNeural'>
                                {t('role.liaoningxiaobei')}
                            </option>
                            <option value='en-US-JennyMultilingualV2Neural'>
                                {t('role.jenny')}
                            </option>
                            <option value='en-US-RyanMultilingualNeural'>
                                {t('role.ryan')}
                            </option>
                        </select>
                    </div> */}

                    {roleState.samples?.length < 5 && (
                        <div className='flex flex-row items-center justify-between px-4 border-b'>
                            <span className='label flex flex-none'>
                                {t('role.conversationSample')}
                            </span>
                            <button
                                onClick={handleAddSample}
                                className={`btn-ghost rounded-md ${
                                    roleState.samples?.length > 0 &&
                                    (!roleState.samples?.[
                                        roleState.samples.length - 1
                                    ].question ||
                                        !roleState.samples?.[
                                            roleState.samples.length - 1
                                        ].answer)
                                        ? 'btn-disabled'
                                        : ''
                                }`}
                                disabled={
                                    roleState.samples?.length > 0 &&
                                    (!roleState.samples?.[
                                        roleState?.samples?.length - 1
                                    ].question ||
                                        !roleState.samples?.[
                                            roleState?.samples?.length - 1
                                        ].answer)
                                }
                            >
                                <MdAdd />
                            </button>
                        </div>
                    )}
                    {roleState.samples?.map((sample, index) => (
                        <div
                            key={index}
                            className='flex flex-none flex-row items-center justify-between px-4'
                        >
                            <div className='flex flex-1 flex-col '>
                                <div className=' mb-2 flex flex-1 flex-row items-center'>
                                    <label className='label flex-none text-sm p-2'>
                                        {t('role.question')}
                                    </label>
                                    <input
                                        type='text'
                                        placeholder={t(
                                            'role.questionPlaceHolder',
                                        )}
                                        value={sample?.question}
                                        className='input input-bordered m-1 flex-1 max-w-lg sm:text-sm'
                                        onChange={(event) =>
                                            handleSampleChange(
                                                index,
                                                'question',
                                                event.target.value,
                                            )
                                        }
                                    />
                                </div>
                                <div className='mb-2 flex-1 flex flex-row items-center'>
                                    <label className='label flex-none text-sm p-2'>
                                        {t('role.answer')}
                                    </label>
                                    <textarea
                                        placeholder={t(
                                            'role.answerPlaceHolder',
                                        )}
                                        value={sample.answer}
                                        className='textarea textarea-bordered m-1 flex-1  max-w-lg sm:text-sm'
                                        rows={2}
                                        onChange={(event) =>
                                            handleSampleChange(
                                                index,
                                                'answer',
                                                event.target.value,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className='flex flex-none min-w-24 justify-end '>
                                <button
                                    onClick={() => handleRemoveSample(index)}
                                    className='btn-ghost p-2 rounded-md'
                                >
                                    <MdRemove />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default RoleEditor;
