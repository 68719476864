// src/components/Pagination.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
}) => {
    const { t } = useTranslation();

    // 计算应该显示的页码范围
    const getPageRange = (currentPageIndex: number, totalPages: number) => {
        const maxPagesToShow = 10;
        let startPage, endPage;

        if (totalPages <= maxPagesToShow) {
            // 总页数小于等于最大显示页码数时，显示所有页码
            startPage = 1;
            endPage = totalPages;
        } else {
            // 总页数多于最大显示页码数时，计算页码范围
            const maxPagesBeforeCurrentPage = 5;
            const maxPagesAfterCurrentPage = 5;
            if (currentPageIndex <= maxPagesBeforeCurrentPage) {
                // 当前页码靠近左边界时
                startPage = 1;
                endPage = maxPagesToShow;
            } else if (
                currentPageIndex + maxPagesAfterCurrentPage >=
                totalPages
            ) {
                // 当前页码靠近右边界时
                startPage = totalPages - maxPagesToShow + 1;
                endPage = totalPages;
            } else {
                // 当前页码在中间时
                startPage = currentPageIndex - maxPagesBeforeCurrentPage + 1;
                endPage = currentPageIndex + maxPagesAfterCurrentPage + 1;
            }
        }
        return { startPage, endPage };
    };

    const { startPage, endPage } = getPageRange(currentPage, totalPages);

    // 生成页码按钮
    const pageNumbers = [];
    for (let page = startPage; page <= endPage; page++) {
        pageNumbers.push(
            <button
                key={page}
                className={`rounded p-1 mx-1 ${
                    currentPage === page - 1
                        ? 'btn-primary bg-primary'
                        : 'btn-ghost'
                }`}
                onClick={() => onPageChange(page - 1)}
            >
                {page}
            </button>,
        );
    }

    return (
        <div className='flex flex-none flex-row justify-start'>
            {/* 页码显示器 */}
            <span className='label mr-4'>
                {currentPage + 1} / {totalPages}
            </span>
            <div className='flex flex-1 flex-row justify-between'>
                {/* 切换页面按钮 */}
                <button
                    className='btn btn-ghost text-primary flex flex-none'
                    onClick={() => onPageChange(currentPage - 1)}
                >
                    {t('global.prev')}
                </button>
                <div className='flex flex-none flex-row justify-between items-center'>
                    {startPage > 1 && (
                        <>
                            <button
                                className={`rounded px-1 mx-1 ${
                                    currentPage === startPage
                                        ? 'btn-primary bg-primary' // 当前页面高亮
                                        : 'btn-ghost'
                                }`}
                                onClick={() => onPageChange(0)}
                            >
                                1
                            </button>
                            {startPage > 2 && <span>...</span>}
                        </>
                    )}
                    {pageNumbers}
                    {endPage < totalPages && (
                        <>
                            {endPage < totalPages - 1 && <span>...</span>}
                            <button
                                className={`rounded  px-1 mx-1 ${
                                    currentPage === endPage
                                        ? 'btn-primary bg-primary' // 当前页面高亮
                                        : 'btn-ghost'
                                }`}
                                onClick={() => onPageChange(currentPage - 1)}
                            >
                                {totalPages}
                            </button>
                        </>
                    )}
                </div>
                <button
                    className='btn btn-ghost text-primary flex flex-none'
                    onClick={() => onPageChange(currentPage + 1)}
                >
                    {t('global.next')}
                </button>
            </div>
        </div>
    );
};

export default Pagination;
