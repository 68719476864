import { Switch } from '@headlessui/react';
import { t } from 'i18next';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiOutlineFile, AiOutlineCloseCircle } from 'react-icons/ai';
import { FiTrash2, FiDownload, FiFileText } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { RiUploadCloud2Fill } from 'react-icons/ri';

import type { IFile } from '../../models/Content';
import {
    useCreateFileMutation,
    useDeleteFileMutation,
    useDownloadFileMutation,
    useGetFilesByRoleIdQuery,
    useUploadFileMutation,
} from '../../services/contentApi';
import { useGetRoleByIdQuery } from '../../services/sessionApi';

export interface FileListItemProps {
    name: string;
    disabled: boolean | undefined;
    onDelete: () => void;
}

const Pagination: React.FC<{
    count: number;
    page: number;
    onPageChange: (page: number) => void;
}> = ({ count, page, onPageChange }) => {
    return (
        <div className='flex space-x-1'>
            {Array.from({ length: count }, (_, i) => i + 1).map((pageNum) => (
                <button
                    key={pageNum}
                    className={`px-3 py-1 rounded ${
                        page === pageNum
                            ? 'btn-primary bg-primary'
                            : 'btn-ghost'
                    }`}
                    onClick={() => onPageChange(pageNum)}
                >
                    {pageNum}
                </button>
            ))}
        </div>
    );
};

export const FileListItem = ({
    name,
    disabled,
    onDelete,
}: FileListItemProps) => (
    <li className='bg-base-100 text-base-content flex items-center justify-between p-1 m-1 border rounded-md'>
        <div className='flex items-center space-x-2'>
            <AiOutlineFile />
            <span
                className={`text-sm truncate w-max-sm
            ${disabled ? 'input-disabled ' : ''}`}
            >
                {name}
            </span>
        </div>
        <button
            className={`btn-ghost p-1 text-sm ${disabled ? 'btn-disabled' : ''}`}
            onClick={onDelete}
            disabled={disabled}
        >
            <AiOutlineCloseCircle />
        </button>
    </li>
);

interface UploadFileState {
    file: File;
    status: string;
}

const getStatusDescription = (status: number): string => {
    switch (status) {
        case -1:
            return t('content.failure');
        case 0:
            return t('content.notStarted');
        case 1:
            return t('content.inProcess');
        case 2:
            return t('content.completed');
        default:
            return t('content.unknown');
    }
};

interface UploaderProps {
    roleId: string;
    type: 'personal' | 'group';
    onClose?: () => void;
}
export const FileUploader: React.FC<UploaderProps> = ({
    roleId,
    type,
    onClose,
}) => {
    const { data: files, refetch: refetchFiles } = useGetFilesByRoleIdQuery(
        {
            roleId,
            isPersonal: type !== 'group',
        },
        {
            skip: !roleId,
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
        },
    );

    const [uploadFile] = useUploadFileMutation();
    const [createFile] = useCreateFileMutation();
    const [deleteFile] = useDeleteFileMutation();
    const [downloadFile] = useDownloadFileMutation();
    const [fileStates, setFileStates] = useState<UploadFileState[]>([]);
    const { data: role } = useGetRoleByIdQuery(roleId);
    const [fileMode, setFileMode] = useState(true);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [paginatedFileList, setPaginatedFileList] = useState<IFile[]>(
        files ?? [],
    );
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined = undefined;

        if (files && files.length > 0) {
            setPaginatedFileList(
                files?.slice((page - 1) * itemsPerPage, page * itemsPerPage),
            );
            // 检查是否有文件处于 inprocess 或 not started 状态
            const hasPendingFiles = files.some(
                (file: IFile) => file.status === 0 || file.status === 1,
            );

            if (hasPendingFiles) {
                intervalId = setInterval(refetchFiles, 2000);
            } else {
            }
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [files, refetchFiles]);

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };
    const handleChangePage = (value: number) => {
        setPage(value);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleContentChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setContent(event.target.value);
    };

    const saveToTextFile = useCallback(async () => {
        if (title && content) {
            setTitle('');
            setContent('');
            await createFile({
                name: title,
                content,
                roleId,
                isPersonal: type !== 'group',
            });
            await refetchFiles();
        }
    }, [title, content, roleId]);

    const onAccepted = useCallback(
        (acceptedFiles: File[]) => {
            setFileStates((prevFileStates) => {
                const newFileStates = [...prevFileStates];
                for (const file of acceptedFiles) {
                    newFileStates.push({ file: file, status: '0' });
                }
                return newFileStates;
            });
        },
        [setFileStates],
    );

    const removeFromList = useCallback((index: number) => {
        setFileStates((prevFileStates) => {
            const newFileStates = [...prevFileStates];
            newFileStates.splice(index, 1);
            return newFileStates;
        });
    }, []);

    const upload = useCallback(async () => {
        try {
            const file = fileStates[0].file;
            // Add check for fileName
            await uploadFile({ file, roleId, isPersonal: type !== 'group' });
            removeFromList(0);
            await refetchFiles();
        } catch (err) {
            console.log(err);
        }
    }, [roleId, fileStates, removeFromList]);

    const handleDeleteFile = useCallback(
        async (id: string) => {
            await deleteFile({ id, roleId });
            setPaginatedFileList(
                paginatedFileList?.filter((x: IFile) => x.id !== id) ?? [],
            );
        },
        [roleId, paginatedFileList, deleteFile],
    );

    const handleDownloadFile = useCallback(
        async (id: string, name: string) => {
            const blob = await downloadFile({ id, name, roleId }).unwrap();
            if (blob) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.target = '_blank';
                a.href = url;
                a.download = name; //注释这一行可以打开该文件，但是不保存
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            }
        },
        [roleId],
    );

    useEffect(() => {
        const handleUploadedFiles = async () => {
            if (fileStates.length > 0) {
                if (fileStates[0].status === '0') {
                    //改变待处理文件的状态
                    setFileStates((prevFileStates) => {
                        const newFileStates = [...prevFileStates];
                        newFileStates[0].status = '1';
                        return newFileStates;
                    });
                    await upload();
                }
            }
        };
        handleUploadedFiles();
    }, [fileStates, upload]);

    const handleListItemClick = useCallback(
        async (id: string, name: string) => {
            if (!fileMode && name.endsWith('.txt')) {
                const blob = await downloadFile({ id, roleId }).unwrap();
                if (blob) {
                    const text = await blob.text();
                    setContent(text);
                    setTitle(name.slice(0, -4));
                }
            }
        },
        [roleId, fileMode],
    );

    const clearInputs = () => {
        setTitle('');
        setContent('');
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDropAccepted: onAccepted,
        noClick: true,
        noKeyboard: true,
        accept: {
            'text/pdf': ['.pdf'],
            'text/txt': ['.txt'],
            'text/md': ['.md'],
            'text/docx': ['.doc', '.docx'],
            'text/pptx': ['.ppt', '.pptx'],
            'text/xlsx': ['.xlsx', '.xls'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
    });

    return (
        <div className='flex flex-col bg-base-100 text-base-content px-2'>
            <div className='flex items-center justify-between w-full py-2'>
                <p className='label text-lg p-2 flex-grow text-left '>
                    {type === 'personal'
                        ? t('content.personal')
                        : t('content.role').replace('{0}', role?.title ?? '')}
                </p>
                <div className='flex flex-row justify-end'>
                    <span className='text-sm'>{t('content.textMode')}</span>
                    <Switch
                        checked={fileMode}
                        onChange={setFileMode}
                        className={`${
                            fileMode ? 'bg-primary' : 'bg-base-300'
                        } mx-2 relative items-center justify-start h-6 w-11 inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-base-100/75`}
                    >
                        <span className='sr-only'>{t('content.fileMode')}</span>
                        <span
                            aria-hidden='true'
                            className={`bg-base-100 ${
                                fileMode ? 'translate-x-6' : 'translate-x-0'
                            } pointer-events-none h-4 w-4 inline-block transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                    </Switch>
                    <span className='text-sm'>{t('content.fileMode')}</span>
                    {onClose && (
                        <button
                            onClick={handleClose}
                            className='btn-primary btn-ghost ml-4 inline-flex justify-center rounded-full'
                            aria-label='Close'
                        >
                            <MdClose className='w-6 h-6 text-primary' />
                        </button>
                    )}
                </div>
            </div>
            {!fileMode ? (
                <div className='flex flex-col items-center'>
                    <div className='flex justify-between w-full'>
                        <input
                            type='text'
                            placeholder={t('content.title')}
                            value={title}
                            onChange={handleTitleChange}
                            className='input input-bordered'
                        />
                        <div className='flex space-x-2'>
                            <button
                                className={`btn btn-primary btn-outline mx-2 inline-flex items-center justify-center px-2 ${
                                    title || content ? '' : 'btn-disabled'
                                }`}
                                onClick={clearInputs}
                                disabled={!title && !content}
                            >
                                {t('content.clear')}
                            </button>
                            <button
                                className={`btn btn-primary btn-outline mx-2 inline-flex items-center justify-center px-2 ${
                                    title && content ? '' : 'btn-disabled'
                                }`}
                                onClick={saveToTextFile}
                                disabled={!title || !content}
                            >
                                {t('global.save')}
                            </button>
                        </div>
                    </div>
                    <textarea
                        placeholder={t('content.content')}
                        rows={5}
                        value={content}
                        onChange={handleContentChange}
                        className='textarea textarea-primary w-full mt-4'
                    />
                </div>
            ) : (
                <div
                    {...getRootProps()}
                    className='label label-text flex-col items-center border rounded p-2'
                >
                    <input {...getInputProps()} />
                    <div className='flex flex-col items-center justify-center '>
                        <RiUploadCloud2Fill className='text-5xl text-primary my-2' />
                        <p className='text-center text-sm '>
                            {t('content.dropFilesHere')}
                        </p>
                        <button
                            className='btn btn-primary  btn-sm m-3 px-3 py-1 '
                            onClick={open}
                        >
                            Upload
                        </button>
                    </div>
                    <div className='flex flex-row items-start flex-wrap justify-start py-2'>
                        {fileStates?.map((fileState, i) => (
                            <FileListItem
                                key={'fileListItem-' + fileState.file.name}
                                name={fileState.file.name}
                                disabled={fileState.status !== '0'}
                                onDelete={() => removeFromList(i)}
                            />
                        ))}
                    </div>
                </div>
            )}
            <div>
                <ul className='list-none flex flex-col items-between'>
                    {paginatedFileList?.map((file: IFile) => (
                        <li
                            key={'listItem-' + file.fileName}
                            className='flex flex-row justify-between items-center'
                        >
                            <div className='flex flex-row content-center flex-auto items-center'>
                                <button
                                    onClick={async () =>
                                        handleListItemClick(
                                            file.id,
                                            file.fileName,
                                        )
                                    }
                                    className='btn btn-ghost btn-sm flex-auto flex flex-row justify-start truncate '
                                >
                                    <FiFileText className='text-xl flex-none mx-2' />
                                    {file.fileName}
                                </button>
                                <div className='flex-none'>
                                    {getStatusDescription(file.status)}
                                </div>
                            </div>
                            <div className='m-2 flex flex-row flex-none space-x-2 '>
                                <button
                                    className={`btn btn-outline btn-sm mx-2 inline-flex items-center justify-center ${
                                        file.status === 2 ? '' : 'btn-disabled '
                                    }`}
                                    aria-label='download'
                                    disabled={file.status !== 2}
                                    onClick={async () =>
                                        handleDownloadFile(
                                            file.id,
                                            file.fileName,
                                        )
                                    }
                                >
                                    <FiDownload />
                                </button>
                                <button
                                    className={`btn btn-outline btn-sm mx-2 inline-flex items-center justify-center`}
                                    aria-label='delete'
                                    onClick={async () =>
                                        handleDeleteFile(file.id)
                                    }
                                >
                                    <FiTrash2 />
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className='flex justify-center mt-2'>
                    <div className='flex justify-center mt-2'>
                        <Pagination
                            count={
                                files
                                    ? Math.ceil(files.length / itemsPerPage)
                                    : 1
                            }
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileUploader;
