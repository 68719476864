//App.tsx
import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineContacts as ContactsIcon } from 'react-icons/ai';
import { IoChatbubbleEllipsesOutline as ChatIcon } from 'react-icons/io5';
import { RiAdminLine } from 'react-icons/ri';
import { Routes, Route } from 'react-router-dom';

import Footer from './components/Footer';
import { Navbar } from './components/navbar';
import Profile from './components/navbar/Profile';
import About from './pages/About';
import Admin from './pages/Admin';
import { Chat } from './pages/Chat';
import NoPage from './pages/NoPage';
const App: React.FC = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const activeAccount = instance?.getActiveAccount();
    const roles = activeAccount?.idTokenClaims?.['roles'];
    const isAdmin = roles?.includes('Admin');
    const navigation = [
        {
            name: `${t('navbar.chat')}`,
            path: '/',
            icon: <ChatIcon className='text-base-100' />,
            element: <Chat />,
        },
        ...(isAdmin
            ? [
                  {
                      name: `${t('navbar.admin')}`,
                      path: '/admin',
                      icon: <RiAdminLine className='text-base-100' />,
                      element: <Admin />,
                  },
              ]
            : []),
        {
            name: `${t('navbar.about')}`,
            path: '/about',
            icon: <ContactsIcon className='text-base-100' />,
            element: <About />,
        },
    ];

    return (
        <div className='h-screen w-screen max-h-screen flex flex-col'>
            <Navbar
                items={navigation}
                siteName={t('title')}
                siteLogo='/icons/logo.svg'
            >
                <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                    <Profile />
                </div>
            </Navbar>
            <Routes>
                {navigation.map((item) => (
                    <Route
                        key={item.path}
                        path={item.path}
                        element={item.element}
                    />
                ))}
                <Route path='*' element={<NoPage />} />
            </Routes>
            <Footer />
        </div>
    );
};

export default App;
