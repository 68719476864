import type { ChangeEvent } from 'react';
import React, { useRef } from 'react';
import { MdPhotoCamera } from 'react-icons/md';

interface ImagePickerProps {
    imageData: string;
    onAccepted: (updatedImageData: string) => void;
    [key: string]: any;
}

export const ImagePicker: React.FC<ImagePickerProps> = ({
    imageData,
    onAccepted,
    ...rest
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const reader = new FileReader();

        if (file) {
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                if (reader.result) {
                    const img = new Image();
                    img.src = reader.result as string;
                    img.onload = function () {
                        const canvas = canvasRef.current;
                        if (canvas) {
                            const ctx = canvas.getContext('2d');
                            if (ctx) {
                                ctx.clearRect(
                                    0,
                                    0,
                                    canvas.width,
                                    canvas.height,
                                );
                                ctx.fillStyle = '#fff';
                                ctx.fillRect(0, 0, canvas.width, canvas.height);
                                const aspectRatio = img.width / img.height;
                                let width = 128;
                                let height = 128;
                                if (aspectRatio > 1) {
                                    height = width / aspectRatio;
                                } else {
                                    width = height * aspectRatio;
                                }
                                const x = (canvas.width - width) / 2;
                                const y = (canvas.height - height) / 2;
                                ctx.drawImage(img, x, y, width, height);
                                const resizedImage = canvas.toDataURL(
                                    'image/jpeg',
                                    0.9,
                                );
                                //const newRole = { ...role, icon: resizedImage };
                                onAccepted(resizedImage);
                            }
                        }
                    };
                }
            };
        }
    };

    return (
        <div
            {...rest}
            className='w-24 h-24 flex items-center justify-center mr-2'
        >
            <div className='input-bordered w-full h-full p-1 border rounded-lg flex items-center justify-center'>
                <input
                    accept='image/jpeg,image/png,image/tiff,image/webp'
                    className='hidden'
                    id='contained-button-file'
                    name='logo'
                    type='file'
                    onChange={handleUploadClick}
                />
                <label
                    htmlFor='contained-button-file'
                    className='label cursor-pointer'
                >
                    {!imageData && (
                        <div className='bg-primary p-4 rounded-full '>
                            <MdPhotoCamera className='text-primary-content h-6 w-6' />
                        </div>
                    )}
                    {!!imageData && (
                        <img
                            src={imageData}
                            alt='LOGO'
                            className='w-full h-full block object-cover rounded-lg'
                        />
                    )}
                    <canvas
                        ref={canvasRef}
                        className='hidden w-32 h-32'
                        width={128}
                        height={128}
                    />
                </label>
            </div>
        </div>
    );
};

export default ImagePicker;
