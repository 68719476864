// src/services/sessionApi.ts
import { createApi } from '@reduxjs/toolkit/query/react';

import type { IRole } from '../models/Role';
import type { ISession } from '../models/Session';

import { axiosBaseQuery } from './baseQuery';

export const sessionApiSlice = createApi({
    baseQuery: axiosBaseQuery({ baseUrl: '' }),
    tagTypes: ['Session', 'Role'],
    endpoints: (builder) => ({
        getRoles: builder.query<IRole[], void>({
            query: () => ({ url: '/roles', method: 'GET' }),
            transformResponse: (response: any) => response,
            providesTags: [{ type: 'Role', id: 'ALL' }],
        }),
        getRoleById: builder.query<IRole, string | undefined>({
            query: (id) => {
                if (!id?.trim()) {
                    return { url: '', method: 'GET', skip: true };
                }
                // 如果 roleId 不为空，正常发出 HTTP 请求
                return { url: `/roles/${id}`, method: 'GET' };
            },
            providesTags: (result, error, id) => [{ type: 'Role', id }],
        }),
        createRole: builder.mutation<IRole, Partial<IRole>>({
            query: (newRole) => ({
                url: '/roles',
                method: 'POST',
                data: newRole,
            }),
            invalidatesTags: [{ type: 'Role', id: 'ALL' }],
        }),
        updateRole: builder.mutation<IRole, Partial<IRole>>({
            query: (updatedRole) => ({
                url: `/roles/${updatedRole.id}`,
                method: 'PUT',
                data: updatedRole,
            }),
            invalidatesTags: (result, error, updatedRole) => [
                { type: 'Role', id: updatedRole.id },
                { type: 'Role', id: 'ALL' },
                { type: 'Session', id: 'ALL' },
            ],
        }),
        shareRole: builder.mutation<IRole, string>({
            query: (roleId) => ({
                url: `/roles/${roleId}/share`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, roleId) => [
                { type: 'Role', id: roleId },
                { type: 'Role', id: 'ALL' },
                { type: 'Session', id: 'ALL' },
            ],
        }),
        deleteRole: builder.mutation<{ success: boolean; id: string }, string>({
            query: (roleId) => ({
                url: `/roles/${roleId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, roleId) => [
                { type: 'Role', id: roleId },
                { type: 'Role', id: 'ALL' },
                { type: 'Session', id: 'ALL' },
            ],
        }),
        getSessions: builder.query<ISession[], void>({
            query: () => ({ url: '/sessions', method: 'GET' }),
            //transformResponse: (response: any) => response,
            providesTags: [{ type: 'Session', id: 'ALL' }],
        }),
        createSessions: builder.mutation<ISession[], string[]>({
            query: (roles) => ({
                url: '/sessions',
                method: 'POST',
                data: { roleIds: roles },
            }),
            invalidatesTags: [{ type: 'Session', id: 'ALL' }],
        }),
        updateSessions: builder.mutation<ISession[], string[]>({
            query: (roles) => ({
                url: `/sessions`,
                method: 'PUT',
                data: { roleIds: roles },
            }),
            invalidatesTags: [{ type: 'Session', id: 'ALL' }],
        }),
        deleteSessions: builder.mutation<void, string[]>({
            query: (roles) => ({
                url: `/sessions`,
                method: 'DELETE',
                data: { roleIds: roles },
            }),
            invalidatesTags: [{ type: 'Session', id: 'ALL' }],
        }),
    }),
    reducerPath: 'sessionApi',
});

export const {
    useGetRolesQuery,
    useGetRoleByIdQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    useShareRoleMutation,
    useGetSessionsQuery,
    useCreateSessionsMutation,
    useUpdateSessionsMutation,
    useDeleteSessionsMutation,
} = sessionApiSlice;
