// src/components/chat/ChatInput.tsx
import {
    AnonymousCredential,
    BlockBlobClient,
    newPipeline,
} from '@azure/storage-blob';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineFileImage } from 'react-icons/ai';
//import { CgAttachment } from 'react-icons/cg';
import { MdSend } from 'react-icons/md';
import { v1 as uuidv1 } from 'uuid';

import type { IRole } from '../../models/Role';
//import FilePreview from '../content/FilePreview';
import {
    useGetUploadUrlMutation,
    useGetDownloadUrlMutation,
} from '../../services/contentApi';
import ImagePreview from '../content/ImagePreview';

const useAutoResizeTextarea = (value: string, maxRows = 8) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            const computedStyle = window.getComputedStyle(textarea);
            const lineHeight = parseInt(computedStyle.lineHeight, 10);
            // 排除内边距和边框的高度
            const paddingTop = parseInt(computedStyle.paddingTop, 10);
            const paddingBottom = parseInt(computedStyle.paddingBottom, 10);
            const borderTopWidth = parseInt(computedStyle.borderTopWidth, 10);
            const borderBottomWidth = parseInt(
                computedStyle.borderBottomWidth,
                10,
            );
            const extraHeight =
                paddingTop + paddingBottom + borderTopWidth + borderBottomWidth;
            textarea.style.height = lineHeight + extraHeight + 'px'; // Reset height to recalculate
            const scrollHeight = textarea.scrollHeight - extraHeight;
            // Add a small buffer to prevent height increase when content just fits one line
            const rows = value
                ? Math.min(maxRows, scrollHeight / lineHeight)
                : 1;
            textarea.style.height = `${rows * lineHeight + extraHeight}px`;
        }
    }, [value, maxRows]);

    return textareaRef;
};

interface ChatInputProps {
    role: IRole | undefined;
    onSend: (input: string, images: string[] | undefined) => Promise<void>;
}

const ChatInput: React.FC<ChatInputProps> = ({ role, onSend }) => {
    const [newMessage, setNewMessage] = useState('');
    //const [images, setImages] = useState<File[]>([]);
    const [getUploadUrl] = useGetUploadUrlMutation();
    const pipeline = newPipeline(new AnonymousCredential());
    const [getDownloadUrl] = useGetDownloadUrlMutation();
    const [images, setImages] = useState<string[]>([]);
    const imgInputRef = useRef<HTMLInputElement>(null);
    //const docInputRef = useRef<HTMLInputElement>(null);
    const textareaRef = useAutoResizeTextarea(newMessage);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    if (!role) {
        return null;
    }
    const handleSend = async () => {
        const msg = newMessage.trim();
        if (images && !msg) {
            return;
        }
        if (msg !== '' || images.length > 0) {
            setNewMessage('');
            clearImagess();
            await onSend(msg, images);
        }
    };

    const handleRemoveImage = (image: string) => {
        setImages(
            (prevImages) => prevImages.filter((img) => img !== image) ?? [],
        );
        if (imgInputRef.current) {
            imgInputRef.current.value = '';
        }
    };

    const handleImageChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const files = event.target.files ? Array.from(event.target.files) : [];
        for (const file of files) {
            if (file.type.startsWith('image/')) {
                setUploadProgress(1);
                const fileName = uuidv1().replace(/-/g, '');
                const uploadUrlResponse = await getUploadUrl({
                    filePath: fileName,
                    period: 60,
                }).unwrap();
                setUploadProgress(10);
                if (uploadUrlResponse) {
                    const blockBlobClient = new BlockBlobClient(
                        uploadUrlResponse,
                        pipeline,
                    );

                    await blockBlobClient.uploadData(file, {
                        blockSize: 4 * 1024 * 1024, // 4MB block size
                        concurrency: navigator.hardwareConcurrency, // Use hardware concurrency
                        onProgress: (ev: any) => {
                            const progress =
                                10 +
                                ((ev?.loadedBytes ?? 0) /
                                    (file.size ?? ev?.loadedBytes + 1)) *
                                    80;
                            setUploadProgress(progress);
                        },
                    });
                    setUploadProgress(90);
                    const downloadUrlResponse = await getDownloadUrl({
                        filePath: fileName,
                        period: 60 * 240,
                    }).unwrap();
                    setUploadProgress(100);
                    if (downloadUrlResponse) {
                        setImages((prevImages) => [
                            ...prevImages,
                            downloadUrlResponse,
                        ]);
                    }
                }
            }
        }
        if (event.target) {
            event.target.value = '';
        }
    };

    const clearImagess = () => {
        setImages([]);
        if (imgInputRef.current) {
            imgInputRef.current.value = '';
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && event.ctrlKey) {
            handleSend();
        }
    };

    const handlePaste = async (
        event: React.ClipboardEvent<HTMLTextAreaElement>,
    ) => {
        const items = event.clipboardData.items;
        for (const item of items) {
            if (item.type.indexOf('image') === 0) {
                if (role?.model === 'gpt_4_vision' || role?.model ==='gpt_4o') {
                    setUploadProgress(1);
                    const file = item.getAsFile();
                    if (file) {
                        const fileName = uuidv1().replace(/-/g, '');
                        const url = await getUploadUrl({
                            filePath: fileName,
                            period: 60,
                        }).unwrap();
                        setUploadProgress(10);
                        if (url) {
                            const blockBlobClient = new BlockBlobClient(
                                url,
                                pipeline,
                            );

                            await blockBlobClient.uploadData(file, {
                                blockSize: 2 * 1024 * 1024, // 4MB block size
                                concurrency: navigator.hardwareConcurrency, // 20 concurrency
                                onProgress: (ev: any) => {
                                    const progress =
                                        10 +
                                        ((ev?.loadedBytes ?? 0) /
                                            (file.size ??
                                                ev?.loadedBytes + 1)) *
                                            80;
                                    setUploadProgress(progress);
                                },
                            });
                            setUploadProgress(90);
                            const downloadUrl = await getDownloadUrl({
                                filePath: fileName,
                                period: 60 * 240,
                            }).unwrap();
                            setUploadProgress(100);
                            if (downloadUrl) {
                                setImages((prevImages) => {
                                    return [...prevImages, downloadUrl];
                                });
                            }
                        }
                    }
                }
            }
        }
    };
    return (
        <div className='flex flex-col flex-none justify-start'>
            <div className='flex-none flex items-center border border-solid border-base-300 rounded-lg p-0 focus-within:outline-0 focus-within:ring-0'>
                <div className='flex flex-col flex-grow p-0'>
                    {uploadProgress > 0 && uploadProgress < 100 && (
                        <progress
                            className='progress progress-primary w-56'
                            value={uploadProgress}
                            max='100'
                        ></progress>
                    )}
                    {images?.length > 0 && (role?.model === 'gpt_4_vision' || role?.model ==='gpt_4o') && (
                        // <FilePreview
                        //     files={images}
                        //     onRemove={handleRemoveFile}
                        // />
                        <ImagePreview
                            images={images}
                            onRemove={handleRemoveImage}
                        />
                    )}
                    <textarea
                        className={`textarea w-full border-0 focus:outline-none focus:ring-0 resize-none px-2 py-0 ${
                            images?.length > 0 && (role?.model === 'gpt_4_vision' || role?.model === 'gpt_4o')
                                ? 'bg-transparent'
                                : ''
                        }`}
                        ref={textareaRef}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder={t('chat.placeholder')}
                        onKeyDown={handleKeyDown}
                        onPaste={handlePaste}
                    />
                </div>
                <button
                    type='button'
                    title='Send message'
                    className='btn-outline p-2 ml-2 rounded-md transition duration-150 ease-in-out'
                    onClick={handleSend}
                >
                    <MdSend />
                </button>
            </div>
            <div className='flex flex-row flex-none justify-start mt-2'>
                {(role?.model === 'gpt_4_vision' || role?.model === 'gpt_4o') && (
                    <div className='flex flex-row flex-none justify-start'>
                        <button
                            className='btn btn-ghost btn-square btn-sm'
                            onClick={() => imgInputRef.current?.click()}
                            title='Attach Images'
                            aria-label='Attach images'
                        >
                            <AiOutlineFileImage className='w-5 h-5' />
                        </button>
                        <input
                            type='file'
                            className='hidden'
                            ref={imgInputRef}
                            onChange={handleImageChange}
                            accept='image/*'
                        />
                    </div>
                )}

                {/* <div className='flex flex-row flex-none justify-start'>
                    <button
                        className='flex items-center justify-center cursor-pointer mx-1 transition duration-150 ease-in-out'
                        onClick={() => docInputRef.current?.click()}
                        title='Attach documents'
                        aria-label='Attach documents'
                    >
                        <CgAttachment className='mr-2 w-5 h-5' />
                    </button>
                    <input
                        type='file'
                        className='hidden'
                        ref={docInputRef}
                        onChange={handleImageChange}
                        accept='.pdf,.docx'
                    />
                </div> */}
            </div>
        </div>
    );
};

export default ChatInput;
