//Selector.tsx
import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import React, { useState, useEffect, useMemo, Fragment } from 'react';
import {
    MdCheck,
    MdSave,
    MdClose,
    MdAdd,
    MdEdit,
    // MdDelete,
    MdRemove,
    MdShare,
} from 'react-icons/md';

import type { IRole, IRoleBase } from '../../models/Role';
import {
    useGetRolesQuery,
    useUpdateSessionsMutation,
    useGetSessionsQuery,
    useShareRoleMutation,
} from '../../services/sessionApi';

import RoleDelete from './RoleDelete';
import RoleEditor from './RoleEditor';

interface SelectorProps {
    currentRoleId: string;
    onClose: () => void;
}

export const Selector: React.FC<SelectorProps> = ({
    currentRoleId,
    onClose,
}) => {
    const [allRoles, setAllRoles] = useState<IRoleBase[]>([]);
    const [selectedRole, setSelectedRole] = useState<IRoleBase>();
    const [initialRoleIds, setInitialRoleIds] = useState<string[]>([]);
    const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);
    const [newRoleEditor, setNewRoleEditor] = useState<boolean>(false);
    const [openRoleEditor, setOpenRoleEditor] = useState<boolean>(false);
    const [openRoleDelete, setOpenRoleDelete] = useState<boolean>(false);
    const { data: rolesData, refetch: refetchRoles } = useGetRolesQuery();
    const [isAlertShareOpen, setIsAlertShareOpen] = useState(false);
    const [shareRole] = useShareRoleMutation();

    const { data: sessionsData, refetch: refetchSessions } =
        useGetSessionsQuery(undefined, {
            refetchOnMountOrArgChange: true,
        });
    const [updateSessions] = useUpdateSessionsMutation();

    useEffect(() => {
        refetchRoles();
        refetchSessions();
    }, [currentRoleId]);

    useEffect(() => {
        if (rolesData) {
            const convertedItems = rolesData.map((item: IRole) => {
                const converted = {
                    category: item.category,
                    id: item.id,
                    title: item.title,
                    icon: item.icon,
                    readonly: item.readonly,
                    shareLevel: item.shareLevel,
                };
                if (!selectedRole && item.id === currentRoleId) {
                    setSelectedRole(item);
                } else if (selectedRole && item.id === selectedRole?.id) {
                    setSelectedRole(item);
                }
                return converted;
            });
            setAllRoles(convertedItems);
        }
    }, [rolesData, currentRoleId]);

    useEffect(() => {
        if (sessionsData) {
            const convertedItems = sessionsData.map((item) => item.roleId);
            setInitialRoleIds(convertedItems);
            setSelectedRoleIds(convertedItems);
        }
    }, [sessionsData]);

    const toggleRoleSelection = (roleId: string) => {
        setSelectedRoleIds((prevSelectedRoleIds) => {
            // 如果只有一个选定的角色并且正是当前点击的角色，则不进行任何操作
            if (
                prevSelectedRoleIds.length === 1 &&
                prevSelectedRoleIds.includes(roleId)
            ) {
                return prevSelectedRoleIds;
            }
            // 否则，切换角色的选定状态
            return prevSelectedRoleIds.includes(roleId)
                ? prevSelectedRoleIds.filter((id) => id !== roleId)
                : [...prevSelectedRoleIds, roleId];
        });
    };

    const handleRoleSelect = (
        event:
            | React.MouseEvent<HTMLElement>
            | React.KeyboardEvent<HTMLDivElement>,
        roleId: string,
    ) => {
        if ('key' in event && event.key !== ' ') {
            return;
        }
        event.preventDefault();
        setSelectedRole(allRoles.find((role) => role.id === roleId));
    };

    const handleToggleSelect = (
        event:
            | React.MouseEvent<HTMLElement>
            | React.KeyboardEvent<HTMLDivElement>,
        roleId: string,
    ) => {
        if ('key' in event && event.key !== ' ') {
            return;
        }
        event.stopPropagation();
        event.preventDefault();
        toggleRoleSelection(roleId);
    };

    const handleEdit = () => {
        setOpenRoleEditor(true);
    };

    const handleAddRole = () => {
        setNewRoleEditor(true);
    };

    const handleShare = async () => {
        setIsAlertShareOpen(false);
        await shareRole(selectedRole?.id ?? '');
    };

    const handleClose = () => {
        onClose();
    };

    const isDirty = useMemo(() => {
        const sortedSelected = [...selectedRoleIds].sort();
        const sortedInitial = [...initialRoleIds].sort();
        return !(
            JSON.stringify(sortedSelected) === JSON.stringify(sortedInitial)
        );
    }, [selectedRoleIds, initialRoleIds]);

    const handleSave = async () => {
        try {
            await updateSessions(selectedRoleIds);
            handleClose();
        } catch (error) {
            // 处理更新失败的情况
        }
    };

    return (
        <>
            {newRoleEditor && (
                <RoleEditor onClose={() => setNewRoleEditor(false)} />
            )}
            {openRoleEditor && (
                <RoleEditor
                    roleId={selectedRole?.id}
                    onClose={() => setOpenRoleEditor(false)}
                />
            )}
            {openRoleDelete && (
                <RoleDelete onClose={() => setOpenRoleDelete(false)} />
            )}

            <Transition appear show={isAlertShareOpen} as={Fragment}>
                <Dialog
                    as='div'
                    className='relative z-10'
                    onClose={() => setIsAlertShareOpen(false)}
                >
                    {/* <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0' />
                    </Transition.Child> */}

                    <div className='fixed inset-0 overflow-y-auto'>
                        <div className='flex min-h-full items-center justify-center p-4 text-center'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 scale-95'
                                enterTo='opacity-100 scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 scale-100'
                                leaveTo='opacity-0 scale-95'
                            >
                                <Dialog.Panel className='bg-base-200 w-full max-w-md transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all'>
                                    <Dialog.Title
                                        as='h3'
                                        className='label text-lg font-medium leading-6'
                                    >
                                        {t('global.information')}
                                    </Dialog.Title>
                                    <div className='mt-2'>
                                        <p className='label text-sm'>
                                            {t('role.shareMessage')}
                                        </p>
                                    </div>

                                    <div className='mt-4 flex justify-center'>
                                        <button
                                            type='button'
                                            className='btn btn-secondary justify-centerpx-4 py-2 text-sm '
                                            onClick={async () => handleShare()}
                                        >
                                            {t('global.confirm')}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            {!openRoleEditor && !newRoleEditor && !openRoleDelete && (
                <div className='flex flex-col flex-1 overflow-auto'>
                    <div className='flex flex-row justify-between p-2'>
                        <p className='label flex-1 text-lg p-2'>
                            {t('role.selectRole')}
                        </p>
                        <div className='flex flex-row flex-none text-sm p-2'>
                            {selectedRole &&
                                !selectedRole.readonly &&
                                selectedRole.shareLevel !== 'All' && (
                                    <div className='flex flex-row justify-end px-2'>
                                        <button
                                            onClick={() =>
                                                setIsAlertShareOpen(true)
                                            }
                                            className={`btn btn-primary btn-outline mr-2 items-center px-2 justify-center text-sm `}
                                        >
                                            <MdShare className='w-6 h-6' />
                                            {t('role.share')}
                                        </button>
                                    </div>
                                )}
                            {selectedRole && !selectedRole.readonly && (
                                <div className='flex flex-row justify-end px-2'>
                                    <button
                                        onClick={handleEdit}
                                        className={`btn btn-primary btn-outline mr-2 items-center px-2 justify-center text-sm`}
                                    >
                                        <MdEdit className='w-6 h-6' />
                                        {t('global.edit')}
                                    </button>
                                </div>
                            )}

                            <div className='flex flex-row justify-end px-2'>
                                <button
                                    onClick={handleSave}
                                    disabled={!isDirty}
                                    className={`btn btn-primary btn-outline mr-2 items-center px-2 justify-center text-sm ${
                                        isDirty ? '' : 'btn-disabled'
                                    }`}
                                    aria-label='Save'
                                >
                                    <MdSave className='w-6 h-6' />
                                    {t('global.confirm')}
                                </button>
                                <button
                                    onClick={handleClose}
                                    className='btn btn-primary btn-ghost items-center justify-center px-2 py-0 rounded-full'
                                    aria-label='Close'
                                >
                                    <MdClose className='w-6 h-6 text-primary' />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='flex-1 flex flex-row flex-wrap gap-2 justify-start content-start m-6'>
                        {allRoles.map((role) => (
                            <div
                                id={role.id}
                                key={role.id}
                                role='button'
                                tabIndex={0}
                                aria-label={role.title}
                                onClick={(event) =>
                                    handleRoleSelect(event, role.id)
                                }
                                onKeyDown={(event) =>
                                    handleRoleSelect(event, role.id)
                                }
                                className={`flex flex-col items-center justify-end relative m-2 ${
                                    selectedRole?.id === role.id
                                        ? 'border-primary border-2 border-solid rounded-lg focus:outline-none'
                                        : 'border rounded-md'
                                }`}
                            >
                                <div
                                    className='absolute top-0 right-0 bg-base-100 w-6 h-6 border border-base-300 rounded-sm cursor-pointer'
                                    role='button'
                                    tabIndex={0}
                                    onClick={(event) =>
                                        handleToggleSelect(event, role.id)
                                    }
                                    onKeyDown={(event) =>
                                        handleToggleSelect(event, role.id)
                                    }
                                >
                                    {selectedRoleIds.find(
                                        (id) => id === role.id,
                                    ) && (
                                        <MdCheck className='w-5 h-5 text-primary absolute top-0 right-0 ' />
                                    )}
                                </div>
                                <img
                                    src={role.icon}
                                    alt={role.title}
                                    className='w-20 h-24 max-w-full max-h-full object-cover'
                                />
                                <p className='text-xs truncate max-w-[96px]'>
                                    {role.title}
                                </p>
                            </div>
                        ))}
                        <div
                            role='button'
                            tabIndex={0}
                            aria-label={t('role.newRole')}
                            onClick={handleAddRole} // 需要实现handleAddRole函数
                            onKeyDown={(event) =>
                                event.key === ' ' && handleAddRole()
                            }
                            className='flex flex-col items-center justify-end m-2 border border-dashed rounded-md cursor-pointer'
                        >
                            <MdAdd className='w-20 h-24 text-primary' />
                            <p className='text-xs'>{t('role.newRole')}</p>
                        </div>
                        {allRoles?.filter((role) => role.readonly === false)
                            ?.length > 0 && (
                            <div
                                role='button'
                                tabIndex={0}
                                aria-label={t('role.deleteRole')}
                                onClick={() => setOpenRoleDelete(true)}
                                onKeyDown={(event) =>
                                    event.key === ' ' && setOpenRoleDelete(true)
                                } // 键盘访问性
                                className='flex flex-col items-center justify-end m-2 border border-dashed rounded-md cursor-pointer'
                            >
                                <MdRemove className='w-20 h-24 text-primary' />
                                <p className='text-xs'>
                                    {t('role.deleteRole')}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Selector;
