import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';

import { authType, AuthType } from '../authConfig';
import { joinUrls } from '../utils/format';

import { getAccessToken } from './auth';

export type BaseQueryArgs = {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
    responseType?: AxiosRequestConfig['responseType'];
    skip?: boolean;
};

export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' },
    ): BaseQueryFn<BaseQueryArgs, unknown, unknown> =>
    async ({ url, method, data, params, headers, responseType, skip }) => {
        try {
            if (skip) {
                return {};
            }
            let token = null;
            if (authType === AuthType.AAD || authType === AuthType.AADB2C) {
                token = await getAccessToken();
                if (!token) {
                    throw new Error('Not authorized request');
                }
            }

            const result = await axios({
                url: joinUrls(import.meta.env.VITE_BACKEND_URI, baseUrl, url),
                method,
                data,
                params,
                headers: token
                    ? {
                          ...headers,
                          Authorization: `Bearer ${token}`,
                      }
                    : {},
                responseType,
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };
