// src/components/chat/FilePreview.tsx
import React from 'react';
import { FiX } from 'react-icons/fi';
interface ImagePreviewProps {
    images: string[];
    onRemove: (image: string) => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ images, onRemove }) => {
    return (
        <div className='flex flex-none flex-col items-start '>
            {images.map((image) => {
                return (
                    <div
                        key={image}
                        className='flex flex-none items-start mr-2 mt-2'
                    >
                        <img
                            src={image}
                            alt='Preview'
                            className='flex flex-nene max-h-32 w-30 object-cover'
                            onError={() => onRemove(image)}
                        />

                        <FiX
                            className='mr-1 flex-none cursor-pointer'
                            onClick={() => onRemove(image)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ImagePreview;
