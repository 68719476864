//Navbar.tsx
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export interface NavigationItem {
    name: string;
    path: string;
    icon: JSX.Element;
    element: JSX.Element;
}

export interface NavbarProps {
    items: NavigationItem[];
    //current: string;
    //setCurrent: (current: string) => void;
    siteName: string;
    siteLogo?: string;
    children?: React.ReactNode;
}

export const Navbar: React.FC<NavbarProps> = ({
    items,
    siteName,
    siteLogo,
    children,
}) => {
    const { t } = useTranslation();
    const location = useLocation();

    let currentPath = location.pathname;
    if (items.filter((item) => item.path === currentPath).length === 0) {
        currentPath = '/';
    }
    const [current, setCurrent] = useState<string>(currentPath);

    return (
        <header className='flex flex-row flex-none shadow-lg bg-primary text-primary-content'>
            <Disclosure as='nav' className='flex-grow shadow-lg'>
                {({ open }) => (
                    <>
                        <div className='mx-auto  px-2 sm:px-6 lg:px-8'>
                            <div className='relative flex h-16 items-center justify-between'>
                                {/* Mobile menu button*/}
                                <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                                    <Disclosure.Button className='relative inline-flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-inset '>
                                        <span className='absolute -inset-0.5' />
                                        <span className='sr-only'>
                                            {t('navbar.openMainMenu')}
                                        </span>
                                        {open ? (
                                            <XMarkIcon
                                                className='block h-6 w-6'
                                                aria-hidden='true'
                                            />
                                        ) : (
                                            <Bars3Icon
                                                className='block h-6 w-6'
                                                aria-hidden='true'
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>

                                <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                                    {/* site logo */}
                                    <div className='flex flex-shrink-0 items-center'>
                                        <img
                                            className='h-12 w-12'
                                            src={siteLogo}
                                            alt='logo'
                                        />
                                    </div>
                                    {/* site Name */}
                                    <div className='flex items-center space-y-1 rounded-md px-2 py-1 text-xl '>
                                        {siteName}
                                    </div>
                                    {/* site pages */}
                                    <div className='hidden sm:ml-6 sm:flex py-2'>
                                        <div className='flex space-x-4 items-center'>
                                            {items.map((item) => (
                                                <a
                                                    key={item.path}
                                                    href={item.path}
                                                    className={`rounded-md px-3 py-2 text-sm font-medium  ${
                                                        current === item.path
                                                            ? 'text-primary-content/40 hover:text-primary-content/20'
                                                            : 'text-primary-content hover:text-primary-content/80'
                                                    }`}
                                                    aria-current={
                                                        current === item.path
                                                            ? 'page'
                                                            : undefined
                                                    }
                                                    onClick={() => {
                                                        setCurrent(item.path);
                                                        console.log(
                                                            `setCurrent ${item.path}`,
                                                        );
                                                    }}
                                                >
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex'>{children}</div>
                            </div>
                        </div>
                        {/* mobile menu */}
                        <Disclosure.Panel className='sm:hidden'>
                            <div className='space-y-1 px-2 pb-3 pt-2 bg-primary text-primary-content'>
                                {items.map((item) => (
                                    <Disclosure.Button
                                        key={item.path}
                                        as='a'
                                        href={item.path}
                                        className={`block rounded-md px-3 py-2 text-base font-medium ${
                                            current === item.path
                                                ? 'bg-primary/40 text-primary-content/40 hover:text-primary-content/20'
                                                : 'text-primary-content hover:text-primary-content/80'
                                        }`}
                                        aria-current={
                                            current === item.path
                                                ? 'page'
                                                : undefined
                                        }
                                        onClick={() => {
                                            setCurrent(item.path);
                                            console.log(
                                                `setCurrent ${item.path}`,
                                            );
                                        }}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </header>
    );
};
