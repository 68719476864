import React from 'react';
import { FiCopy } from 'react-icons/fi';
import type { ExtraProps } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
    vs,
    // vscDarkPlus,
} from 'react-syntax-highlighter/dist/esm/styles/prism';

export const CodeBlock: React.FC<
    React.ClassAttributes<HTMLElement> &
        React.HTMLAttributes<HTMLElement> &
        ExtraProps & { customStyle?: React.CSSProperties }
> = (props) => {
    const { children, className, node, customStyle, ...rest } = props;
    // Extract the language from the className
    const languageMatch = /language-(\w+)/.exec(className || '');
    const language = languageMatch?.[1] || '';

    const codeText = String(children).replace(/\n$/, '');

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(codeText);
        } catch (err) {
            console.error("can't copy text': ", err);
        }
    };
    if (language) {
        return (
            <div className='flex flex-1 flex-grow flex-col border-0 overflow-y-auto relative'>
                <button
                    onClick={handleCopyClick}
                    className='btn btn-ghost btn-square btn-sm absolute right-2 top-4'
                >
                    <FiCopy className='h-4 w-4' />
                </button>
                <code {...rest} className={className}>
                    <SyntaxHighlighter
                        customStyle={customStyle}
                        lineProps={{
                            style: {
                                wordBreak: 'break-all',
                                whiteSpace: 'pre-wrap',
                            },
                        }}
                        style={vs}
                        language={language}
                        wrapLines={true}
                        showLineNumbers={false}
                        PreTag='div'
                        children={codeText}
                    />
                </code>
            </div>
        );
    } else {
        return (
            <code
                className={`${className} whitespace-pre-wrap break-words overflow-x-auto font-bold italic`}
            >
                {children}
            </code>
        );
    }
};
