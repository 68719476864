//store.ts
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import type { Dispatch, MiddlewareAPI } from 'redux';
//import { persistReducer, persistStore } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';

import { chatApiSlice } from '../services/chatApi';
import { contentApiSlice } from '../services/contentApi';
import { messageApiSlice } from '../services/messageApi';
import { sessionApiSlice } from '../services/sessionApi';

import type { AppState } from './features/app/AppState';
import type { RoleState } from './features/role/RoleState';
import {
    loadStateFromSessionStorage,
    sessionStorageMiddleware,
} from './middlewares/sessionStorageMiddleware';
import rootReducer from './rootReducer';

export type StoreMiddlewareAPI = MiddlewareAPI<Dispatch, RootState>;

// const persistConfig = {
//     key: 'root',
//     storage,
//     whitelist: [chatApiSlice.reducerPath], // 指定需要持久化的reducer
// };

//const persistedReducer = persistReducer(persistConfig, rootReducer);
const preloadedState: RootState | undefined = loadStateFromSessionStorage();

export const store = configureStore({
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            sessionApiSlice.middleware,
            chatApiSlice.middleware,
            messageApiSlice.middleware,
            contentApiSlice.middleware,
            sessionStorageMiddleware,
        ),
    //reducer: persistedReducer,
    reducer: rootReducer,
    preloadedState,
});

//export const persistor = persistStore(store);

export interface RootState {
    app: AppState;
    role: RoleState;
    [sessionApiSlice.reducerPath]: ReturnType<typeof sessionApiSlice.reducer>;
    [chatApiSlice.reducerPath]: ReturnType<typeof chatApiSlice.reducer>;
    [messageApiSlice.reducerPath]: ReturnType<typeof messageApiSlice.reducer>;
    [contentApiSlice.reducerPath]: ReturnType<typeof contentApiSlice.reducer>;
}

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
